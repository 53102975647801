import Taro from '@tarojs/taro';
import { observable, action, toJS } from 'mobx';
import { last, cloneDeep, uniqBy } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import Client from '@indata/im-sdk';
import globalEvent, { events } from '@/utils/event';
import { getStartConversationDate } from '@/utils/common';
import { reportError, reportLog } from '@/utils';
import {
  IFetchChatListRequestParams,
  Values,
  ILiveChatItem,
  IAiConfigInfo,
  MsgType,
  ChatItem,
  IParsedContent,
  ISatisfyServiceParams,
  ISourceSetting,
  LiveAction,
  IReceiptContent,
  FeedbackPostNoticeData,
} from '@/declare/index';
import {
  sendMessage,
  getHistoryList,
  getVisitorWelcome,
  getAiConfigInfo,
  cleanUnreadSignal,
  satisfyService,
  getSourceSetting,
  getExpressList,
  fetchWaittingId,
} from '@/service/index';

import initImSdk from './im-sdk';
import { RootStore } from '../index';

enum ScheduleAction {
  csClosed = 'csClosed',
  reception = 'reception',
  serviceTransferContent = 'serviceTransferContent',
  sessionStart = 'sessionStart',
  receptionistOffWork = 'receptionistOffWork',
  waiting = 'waiting',
  timeoutNoReply = 'timeoutNoReply',
  noReceptionistOnline = 'noReceptionistOnline',
  waitingTimeOut = 'waitingTimeOut',
  noReceptionistBusy = 'noReceptionistBusy',
}

// 灰色提示
const SCHEDULE = [
  'csClosed',
  'reception',
  'serviceTransferContent',
  'sessionStart',
  'receptionistOffWork',
];
// 白色体统提示
const WHITE_SCHEDULE = ['waiting', 'timeoutNoReply', 'noReceptionistOnline', 'noReceptionistBusy'];

const ATTACHMENT_TYPE_MAP = {
  PHOTO: MsgType.IMAGE,
  VIDEO: MsgType.VIDEO,
  TEXT: MsgType.TEXT,
  ADDRESS: MsgType.LOCATION,
  DOCUMENT: MsgType.FILE,
  IMAGE: MsgType.IMAGE,
  FILE: MsgType.FILE,
};

const MAX_TYPING_TIME = 5000;
const HANDLE_TYPING_LAST_TIME = 30 * 1000;

let timer;

export class Ws {
  // ws的链接id
  @observable connectionId = '';

  @observable waittingMsgId = 0;

  // 引用消息的id
  @observable quoteMessage?: ILiveChatItem = undefined;

  @observable name = '';

  @observable avatar = '';

  @observable imClient: Client;

  @observable readMsgId = 0;

  @observable conversationId = '';

  @observable liveChatList: ILiveChatItem[] = [];
  @observable historyChatList: ILiveChatItem[] = [];

  @observable isTyping: boolean = false;

  root: RootStore;
  constructor(root: RootStore) {
    this.root = root;
    globalEvent.on(events.ON_LOAD, () => {
      this.initImSdk();
    });
    // 断网重连
    globalEvent.on(events.SDK_SOCKET_RECONNECTED, () => {
      this.initImSdk(true);
    });
    // console.log(urlParam());
    // this.root.sceneStore.setUrlParams(urlParam());
  }

  cleanUnread = () => {
    if (document.visibilityState === 'visible' && this.conversationId) {
      const { sourceId } = this.root.sceneStore.urlParams;
      cleanUnreadSignal(sourceId, this.conversationId);
      console.log('cleanUnread');
    }
  };

  @action
  setIsTyping = () => {
    clearTimeout(timer);
    this.isTyping = true;
    timer = setTimeout(() => {
      this.isTyping = false;
    }, MAX_TYPING_TIME);
  };

  @action
  async initImSdk(reconnect: boolean = false) {
    // 防止多连接
    if (this.imClient) {
      if (!reconnect) return;
      this.imClient.close();
    }

    try {
      const { client, connectionId, name, avatar } = await initImSdk(reconnect);
      this.imClient = client;
      this.connectionId = connectionId;
      this.name = name;
      this.avatar = avatar;

      // 页面切回时已读状态更新
      document.addEventListener('visibilitychange', this.cleanUnread, false);

      // 第一次加载
      // this.fetchLiveList(0, 20);
      this.imClient.on('serverPush', async (data: ChatItem) => {
        console.log('serverPush  ===>', data, new Date(), this);
        reportLog(`访客 ${data?.to} 收到 ${data?.from} 的消息`, data);
        // 获取业务方推送的消息
        const { from, content, msgType, createTime, nickname, to } = data;
        this.getWaittingTimeOutMsgId();

        if (content) {
          content['isServerPush'] = true;
        }
        let quoteMess;

        if (content?.quoteMess) {
          // @ts-ignore
          quoteMess = JSON.parse(content?.quoteMess || '');
          try {
            const {
              msgContent: quoteMessContent,
              msgTypeAction: quoteMessTypeAction,
              url,
            } = this.parseMsgContentAndType(quoteMess?.content, quoteMess?.msgType, 'quoteMess');

            // @ts-ignore
            quoteMess['content'] = quoteMessContent;
            quoteMess['msgType'] = quoteMessTypeAction;
            quoteMess['isSelf'] = this.judgementIsSelf(from, to);
            quoteMess['url'] = url;
          } catch (e) {
            console.log('quoteMess parse error', e);
          }
        }
        // 解决用户打开两个窗口时，消息显示错落的问题
        if (msgType === MsgType.TEXT && this.judgementIsSelf(from, to)) {
          content['isSelf'] = true;
        }
        const {
          conversationId,
          action: actionType,
          msgId,
          content: contentText,
          conversationServiceId,
        } = content;

        this.conversationId = conversationId;
        // 处理排队状态
        const { permissionStore } = this.root;
        if (actionType === 'waiting' && !permissionStore.waittingInfo?.isWaitting) {
          permissionStore.setWaitting({
            conversationId: conversationId,
            conversationServiceId: conversationServiceId,
            isWaitting: true,
          });
        }

        if (
          actionType === 'csClosed' ||
          actionType === 'reception' ||
          actionType === 'noReceptionistOnline' ||
          this.judgementIsServerSend(from, to)
        ) {
          permissionStore.setWaitting({
            isWaitting: false,
            conversationId: conversationId,
            conversationServiceId: conversationServiceId,
          });
        }

        // 已读消息处理
        if (data.msgType === MsgType.NOTICE && data.content.noticeType === 'peerRead') {
          this.readMsgId = data.content.readMark;
          return;
        }

        // 消息撤回处理
        if (msgType === MsgType.ACTION && actionType === 'receptionistRevoke') {
          this.revokeMessage(msgId, contentText);
          return;
        }

        if (
          msgType === MsgType.RECEIPT_NOTICE &&
          (content as IReceiptContent).event === 'update' &&
          +(content as IReceiptContent).formId === +this.root.feedbackStore.formId
        ) {
          globalEvent.emit(events.UPDATE_FEEDBACK);
        }

        const { msgContent, msgTypeAction } = this.parseMsgContentAndType(
          content,
          msgType,
          undefined,
        );

        const baseData = {
          ...content, // include ===> conversationId, conversationServiceId, isSelf, msgId,content
          visitorId: from, // visitorId映射
          msgType: msgTypeAction,
          content: msgContent,
          updateTime: createTime, // 新消息映射
          name: nickname, // 新消息映射
          avatar: data.avatar,
          from,
          to,
          quoteMess,
        };

        if (msgType === MsgType.NOTICE && (msgContent as IParsedContent)?.noticeType === 'Typing') {
          this.setIsTyping();
          return;
        }

        // 视频通话类消息处理
        if (msgType === MsgType.LIVE) {
          const videoMsgContent = msgContent as IParsedContent;
          if (videoMsgContent.action !== LiveAction.LiveLog) {
            this.root.videoStore.handleLiveMsg(videoMsgContent);
            return;
          }
        }

        // 回单寄件通知
        if (msgType === MsgType.FEEDBACK_POST_NOTICE) {
          const { instanceId } = (msgContent as unknown) as FeedbackPostNoticeData;
          if (instanceId) {
            getExpressList({ instanceId }).then((res) => {
              if (res === false) return;
              globalEvent.emit(events.RECEIPT_EXPRESS_CHANGE, {
                instanceId,
                postList: res,
              });
            });
          }
        }

        // 接到机器人消息拉到最底部
        if (content?.isRobot) {
          globalEvent.emit(events.GOTO_BOTTOM);
        }

        // 处理命中话术中的额外消息
        // @ts-ignore
        let attachments = baseData?.content?.attachment || [];
        if (attachments.length > 0) {
          attachments = this.parseAttachments(attachments, data);
        }

        const list = this.liveChatList.concat([baseData, ...attachments]);

        this.liveChatList = uniqBy(
          list,
          (item) => `${item.conversationId}/${item.key}/${item.msgId}`,
        );

        globalEvent.emit(events.RECEIVE_MESSAGE, baseData);
        if (
          (msgType === MsgType.TEXT ||
            msgType === MsgType.FILE ||
            msgType === MsgType.IMAGE ||
            msgType === MsgType.LOCATION) &&
          !document.hidden
        ) {
          const { sourceId } = this.root.sceneStore.urlParams;
          cleanUnreadSignal(sourceId, conversationId);
        }
        this.isTyping = false;
      });
    } catch (e) {
      console.log('初始化im出错', e);
    }
  }

  @action revokeMessage = (msgId: number, contentText) => {
    this.historyChatList = this.historyChatList.map((message) => {
      if (message.quoteMess && message.quoteMess?.msgId === msgId) {
        message.quoteMess.isRevoke = true;
        message.quoteMess.revoke = true;
      }
      if (message.msgId === msgId) {
        return {
          ...message,
          isRevoke: true,
          content: {
            text: contentText,
          },
        };
      }
      return message;
    });
    this.liveChatList = this.liveChatList.map((message) => {
      if (message.quoteMess && message.quoteMess?.msgId === msgId) {
        message.quoteMess.isRevoke = true;
        message.quoteMess.revoke = true;
      }
      if (message.msgId === msgId) {
        return {
          ...message,
          isRevoke: true,
          content: {
            text: contentText,
          },
        };
      }
      return message;
    });
    if (this.quoteMessage?.msgId === msgId) {
      const result = cloneDeep(this.quoteMessage) || {};
      if (typeof result.content === 'object') {
        result.content.text = '该消息已被撤回';
      }
      globalEvent.emit(events.MESSAGE_QUOTE, result);
    }
  };

  @action
  fetchVisitorWelcome = async (robotInfo: {
    robotPrologue: string;
    robotName: string;
    robotAvatar: string;
  }) => {
    const { sourceId } = this.root.sceneStore.urlParams;
    const firstWelcome = await getVisitorWelcome({ sourceId: sourceId || 'null' });
    if (firstWelcome && firstWelcome.needSendWelcome) {
      const { welcomeAnswer, senderName, senderAvatar } = firstWelcome;
      this.liveChatList.push({
        conversationId: '',
        isSelf: false,
        isRobot: false,
        content: { text: welcomeAnswer, isWelcome: true },
        msgType: MsgType.TEXT,
        isFirstWelcome: true,
        msgId: 0,
        name: senderName, // 新消息映射
        avatar: senderAvatar,
      });
    } else {
      this.liveChatList.push({
        conversationId: '',
        isSelf: false,
        isRobot: false,
        content: { text: robotInfo.robotPrologue, isWelcome: true },
        msgType: MsgType.TEXT,
        isFirstWelcome: true,
        msgId: 0,
        name: robotInfo.robotName, // 新消息映射
        avatar: robotInfo.robotAvatar,
      });
    }
  };

  @action
  fetchSourceSetting = async () => {
    const { sourceId } = this.root.sceneStore.urlParams;
    let res = {} as ISourceSetting;
    try {
      res = await getSourceSetting(sourceId);
    } catch (err) {
      console.error('获取网站配置失败：', err);
    }

    return res;
  };

  @action
  fetchAiConfigInfo = async () => {
    const { sourceId } = this.root.sceneStore.urlParams;
    let res = {} as IAiConfigInfo;
    try {
      res = await getAiConfigInfo(sourceId || 'null');
      const { sourceSettingPageName } = res || {};
      if (sourceSettingPageName) {
        document.title = sourceSettingPageName;
      }
    } catch (err) {
      console.error('获取AI配置失败：', err);
    }
    return res;
  };

  /** 获取历史消息记录列表
   *  @param msgId @type {number} 获取从 msgId 时间线开始之前的 limit 条信息，如果传 0 就是获取最新的 limit 条信息
   *  @param limit @type {number} 获取消息的数量
   */
  @action
  fetchHistoryList = async (msgId: number, limit: number) => {
    const { sourceId } = this.root.sceneStore.urlParams;
    const param: IFetchChatListRequestParams = { limit, sourceId };
    if (msgId) {
      param.msgId = msgId;
    }

    const res = await getHistoryList(param);
    if (Array.isArray(res)) {
      const historyChatList = this.parseData(res);
      if (msgId) {
        // console.log('historyChatList  ====>', historyChatList.slice(), this.historyChatList.slice(), historyChatList.slice().concat(this.historyChatList.slice()));
        this.historyChatList = uniqBy(
          historyChatList.slice().concat(this.historyChatList.slice()),
          (item) => `${item.conversationId}/${item.key}/${item.msgId}`,
        );
      } else {
        this.historyChatList = historyChatList;
      }
      return historyChatList;
    }
  };
  /** 重连刷新活跃聊天记录 */
  @action
  refreshActiveList = async () => {
    const { sourceId } = this.root.sceneStore.urlParams;

    // 获取当前活跃聊天记录 + 30 条
    const fetchLenth = this.liveChatList.length + 30;

    const param: IFetchChatListRequestParams = { limit: fetchLenth, sourceId };

    const res = await getHistoryList(param);

    if (Array.isArray(res)) {
      const newLiveList = this.parseData(res);
      // 截取到最后一个历史记录 + 1
      const lastHistoryChatMsg = last(this.historyChatList);

      // 最后一条历史记录在新拉取记录中的位置，没有那么就是 -1；
      const lastHistoryMsgIndex = newLiveList.findIndex(
        (it) => it.msgId === lastHistoryChatMsg?.msgId,
      );

      // 找到最后一条历史记录以后的 活跃聊天记录
      const filteredList = newLiveList.filter((_, i) => i > lastHistoryMsgIndex);

      // 按 msgId 从小到大排序
      // fix: 过滤掉排队中的消息
      const mergedList = [...filteredList, ...this.liveChatList]
        .sort((a, b) => a.msgId - b.msgId)
        ?.filter((item) => item.action !== 'waiting');

      // 放到活活跃信息列表中
      this.liveChatList = uniqBy(
        mergedList,
        (item) => `${item.conversationId}/${item.key}/${item.msgId}`,
      );
      return this.liveChatList;
    }
  };

  @action
  checkHandleTyping = () => {
    const lastMsg = [...this.liveChatList].pop() || [...this.historyChatList].pop();
    if (!lastMsg || !lastMsg.updateTime) return false;
    const currentTime = new Date().valueOf();
    return currentTime - +lastMsg.updateTime <= HANDLE_TYPING_LAST_TIME;
  };

  @action
  sendMessage = async (data: any, onSuccess?: Function, onFailure?: Function) => {
    const { sourceId } = this.root.sceneStore.urlParams;
    const message = {
      msgType: data.msgType,
      content: JSON.stringify({
        ...data.content,
      }),
      businessNo: sourceId || '',
    };
    // 消息引用
    if (this.quoteMessage) {
      message['quoteMsgId'] = this.quoteMessage?.msgId;
      message['quoteMess'] = this.quoteMessage;
    }

    try {
      if (!this.connectionId) {
        Taro.showToast({
          title: '链接已断开，请刷新页面后重试',
          icon: 'none',
        });
        return;
      }
      this.getWaittingTimeOutMsgId();
      const res = await sendMessage(this.connectionId, sourceId, {
        ...message,
        quoteMess: undefined,
      });
      console.log('success', res);
      if (!res || typeof res !== 'object') return;

      const { conversationId, conversationServiceId, msgId, createTime } = res;
      const msgData = {
        conversationId,
        conversationServiceId,
        isSelf: true,
        isRobot: false,
        msgId,
        updateTime: createTime,
        quoteMess: toJS(this.quoteMessage),
        content: data.content,
        msgType: data.msgType,
        name: this.name,
        avatar: this.avatar,
        read: data.read,
      };
      // @ts-ignore
      this.liveChatList.push(msgData);
      onSuccess && onSuccess();
    } catch (e) {
      console.log('发送消息失败：', e);
      Taro.showToast({
        title: '网络连接已断开，请刷新页面后重试',
        icon: 'none',
      });
      // 记录发送失败日志
      reportError(new Error('访客--send接口消息发送失败'), {
        connectionId: this.connectionId,
        message,
      });
      onFailure && onFailure();
    }
  };

  @action
  sendSatisfyFeedback = async (params: ISatisfyServiceParams) => {
    const { sourceId } = this.root.sceneStore.urlParams;
    await satisfyService(sourceId, params);

    this.liveChatList.push({
      conversationId: '',
      isSelf: false,
      isRobot: true,
      content: { text: '收到您的评价了，我们会努力做到更好！' },
      msgType: MsgType.TEXT,
      // @ts-ignore
      msgId: uuidV4(),
      name: '',
      avatar: '',
    });
  };

  @action('setValues')
  setValues = (values: Values<Ws>) => {
    Object.keys(values).forEach((key) => (this[key] = values[key]));
  };

  parseMsgContentAndType(
    content,
    msgType,
    type,
  ): { msgContent: IParsedContent | string; msgTypeAction: MsgType; url?: string } {
    let msgContent = {} as IParsedContent | string;
    let msgTypeAction = '' as MsgType;
    let url;
    try {
      if (typeof JSON.parse(content.content) !== 'object') {
        msgContent = content.content;
      } else {
        msgContent = {
          ...JSON.parse(content.content),
          // 满意度评价相关组件中使用
          conversationServiceId: content.conversationServiceId,
          // video 组件中使用
          sourceId: content.sourceId,
        };
      }
    } catch (err) {
      // 解析错误
      msgContent = content.content;
    }

    // 特殊的事务
    if (msgType === MsgType.SCHEDULE && content.action === ScheduleAction.waitingTimeOut) {
      msgTypeAction = MsgType.WAITING_TIMEOUT_TIP;
    }

    // 灰色的
    if (msgType === MsgType.SCHEDULE && SCHEDULE.includes(content.action!)) {
      msgTypeAction = MsgType.NOTICE;
      switch (content.action) {
        case 'sessionStart':
          msgContent = getStartConversationDate(+msgContent);
          break;
        // 人工接入 或者 结束会话都会使继续排队无效
        case ScheduleAction.csClosed:
        case ScheduleAction.reception:
          globalEvent.emit(events.DISABLE_WAITING_BTN);
          break;
        default:
          break;
      }
    }

    if (type === 'quoteMess') {
      url = content?.url;
    }

    // 白色的
    if (msgType === MsgType.SCHEDULE && WHITE_SCHEDULE.includes(content.action)) {
      msgTypeAction = MsgType.TEXT;
      msgContent = { text: content.content } as IParsedContent;

      // 无客服在线时重置视频发起状态
      if (content.action === 'noReceptionistOnline') {
        this.root.videoStore.isInVideo = false;
      }
    }

    if (msgType !== MsgType.SCHEDULE) {
      msgTypeAction = msgType;
    }
    return { msgContent, msgTypeAction, url };
  }

  parseAttachments(attachments, data): ILiveChatItem[] {
    const { content, nickname } = data;
    const { conversationId, msgId } = content;

    const result = attachments.reduce((prevResult, attachment, index) => {
      const { type, contents } = attachment;
      const attachmentMsgType = ATTACHMENT_TYPE_MAP[type];
      const allContents = (contents || []).map((contentItem, contentIndex) => {
        const contentData = {
          key: `${conversationId}/${msgId}/${index}-${contentIndex}`,
          name: nickname,
          msgId,
          isSelf: false,
          isRobot: true,
          msgType: attachmentMsgType,
          content: {} as any,
        };
        switch (attachmentMsgType) {
          case MsgType.TEXT:
            contentData.content.text = contentItem;
            break;
          case MsgType.IMAGE:
          case MsgType.VIDEO:
            contentData.content.url = contentItem;
            break;
          case MsgType.FILE:
            contentData.content.type =
              contentItem?.url?.replace(/.*\.(.*)$/, '$1') ||
              contentItem?.replace(/.*\.(.*)$/, '$1');
            contentData.content.url = contentItem?.url || contentItem;
            contentData.content.title = contentItem?.name || contentItem;
            break;
          case MsgType.LOCATION:
            contentData.content.address = contentItem.address;
            contentData.content.longitude = contentItem.longitude;
            contentData.content.latitude = contentItem.latitude;
            break;
          default:
            break;
        }
        return contentData;
      });

      return prevResult.concat(allContents);
    }, []);
    return result;
  }

  parseData(list: ChatItem[]) {
    // 抹平数据
    const result = list
      .reduce((parsedList, item: ChatItem) => {
        const {
          from,
          content,
          msgType,
          createTime,
          nickname,
          avatar,
          read,
          revoke,
          extName,
          quoteMess,
          operationType,
        } = item;
        const { msgId } = content;
        // @ts-ignore
        const { msgContent, msgTypeAction } = this.parseMsgContentAndType(content, msgType);
        if (quoteMess) {
          // @ts-ignore
          quoteMess['url'] = quoteMess?.content?.url;
          const {
            msgContent: quoteMessContent,
            msgTypeAction: quoteMessTypeAction,
            // @ts-ignore
          } = this.parseMsgContentAndType(quoteMess?.content, quoteMess?.msgType);
          // @ts-ignore
          quoteMess['content'] = quoteMessContent;
          quoteMess['msgType'] = quoteMessTypeAction;
        }

        const attachments = (msgContent as IParsedContent)?.attachment || [];
        let extraChatItems = [] as ILiveChatItem[];
        if (attachments.length > 0) {
          extraChatItems = this.parseAttachments(attachments, item);
        }

        let extraData: Record<string, any> = {};
        // 处理历史排队消息中超时排队的问题
        if (msgType === MsgType.SCHEDULE && content.action === ScheduleAction.waitingTimeOut) {
          extraData.waittingTimeOutDisable = true;
        }
        const newData = [
          ...extraChatItems.reverse(),
          {
            ...content, // include ===> conversationId, conversationServiceId, isSelf, msgId,content
            content: msgContent,
            visitorId: from, // visitorId映射
            msgType: msgTypeAction,
            msgId,
            updateTime: createTime, // 新消息映射
            name: nickname, // 新消息映射
            avatar,
            read,
            isRevoke: revoke,
            quoteMess,
            extName,
            operationType,
            ...extraData,
          },
        ];
        // if (extraChatItems.length && !(msgContent as IParsedContent)?.text) {
        //   newData.splice(extraChatItems.length - 1, 1);
        // }

        return parsedList.concat(newData);
      }, [] as ILiveChatItem[])
      .reverse();
    // console.log(result, 'history list');
    return result;
  }

  judgementIsSelf = (from: string, to: string) => {
    const [fromTag] = from?.split('#');
    const [toTag] = to?.split('#');
    // 访客发给客服
    if (fromTag === 'V' && toTag === 'R') {
      return true;
    }
    return false;
  };
  /**
   * 是否为座席端人工发送的消息
   * @param from
   * @param to
   * @returns
   */
  judgementIsServerSend = (from: string, to: string) => {
    const [fromTag] = from?.split('#');
    const [toTag] = to?.split('#');
    // 客服发给访客
    if (fromTag === 'R' && toTag === 'V') {
      return true;
    }
    return false;
  };

  getWaittingTimeOutMsgId = async () => {
    try {
      const lastestId = await fetchWaittingId();
      this.waittingMsgId = lastestId;
    } catch (e) {
      console.log(e);
    }
  };
}

export default Ws;
