import qs from 'query-string';
// queryString 将对象转 参数
// 使用 btoa base64 编码
// 使用 encodeURIComponent 做成 url 可填写的字符串
// 拼接key
// queryString 将对象转 参数
// 使用 btoa base64 编码
// 使用 encodeURIComponent 做成 url 可填写的字符串
// 拼接key
export const queryEncode64 = (obj: Record<string | number, any>, key?: string) => {
  const qsStr = qs.stringify(obj);
  const base64 = Buffer.from(qsStr).toString('base64');
  const encrypted = encodeURIComponent(base64);
  return key ? `${key}=${encrypted}` : encrypted;
};

// 使用 decodeURIComponent 解码
// 使用 atob base64 解码
// queryParse 将 query 转换为对象
export const queryDecode64 = <T extends number | string | symbol>(
  encrypted: string,
  key?: string,
) => {
  try {
    if (key) {
      encrypted = qs.parse(encrypted)[key] as string;
    }
    const base64 = decodeURIComponent(encrypted);
    const qsStr = Buffer.from(base64, 'base64').toString();
    const obj = qs.parse(qsStr);
    console.log('obj is', obj);
    return obj as { [key in T]: string };
  } catch (error) {
    console.log('error is', error);
    return {} as { [key in T]: string };
  }
};
