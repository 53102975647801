import UA from '@/utils/ua';
import { AutomaticListItem } from './intelligent-receipt';
import { SatisfyStatus } from './message';

export * from './message';

export type Values<T> = {
  [P in keyof T]?: T[P];
};

export enum ReceptionMode {
  human,
  ai,
  sale_bot,
}

// web版输入框两种模式
export enum SenderMode {
  human,
  ai,
}

export interface IFetchChatListRequestParams {
  limit: number;
  sourceId: string;
  msgId?: number;
}

export interface ISatisfyRobotReplyParams {
  conversationServiceId: string;
  conversationId: string;
  msgId: number;
  status: SatisfyStatus;
  /** 评价内容 */
  commentContent?: string;
  /** 满意度标签 */
  labelList?: {
    labelId: number;
    labelName: string;
  }[];
}

export interface ISatisfyServiceParams extends ISatisfyRobotReplyParams {}

export interface IVisitorWelcomeResponse {
  welcomeAnswer: string;
  senderName: string;
  senderAvatar: string;
  needSendWelcome: boolean;
}

export interface ISendMessageResponse {
  conversationId: string;
  conversationServiceId: string;
  msgId: number;
  createTime: number;
  newConversationService: boolean;
}

export interface IQuestionGuideItem {
  questions: string[];
  id: string;
  avatar: string;
  title: string;
}

export interface IFunctionGuideItem {
  action: string;
  id: string;
  avatar: string;
  title: string;
  content: string;
}

export interface IAiConfigInfo {
  receptionMode: ReceptionMode;
  questionGuideList: IQuestionGuideItem[];
  functionGuideList: IFunctionGuideItem[];
  sourceSettingPageName: string;
  sourceSettingId: string;
  robotGreetings: string;
  robotPrologue: string;
  robotName: string;
  robotAvatar: string;
  sourceSettingSubTitle: string;
  sourceSettingTitle: string;
  sourceSettingTitleIcon: string;
  color?: string;
  automaticSwitch?: boolean;
  automaticIdName?: AutomaticListItem[];
  voiceSwitch?: boolean;
  textTrVoiceSwitch?: boolean;
  // 退出排队
  exitQueue?: boolean;
}

/** 坐席服务状态 */
export interface FetchSessionStatusParams {
  sourceId: string;
  visitorId: string;
}

export interface ReceptionistServingRes {
  /** 业务编号 */
  businessNo: string;
  /** 上一次会话的 */
  sourceId: string;
  /** 会话Id */
  conversationId: string;
  /** 会话服务Id */
  conversationServiceId: string;
  /** 是否正在服务中(包含机器人和人工) */
  isServing: boolean;
  /** 是否正在被人工坐席服务中 */
  isReceptionistServing: boolean;
}

export enum BizType {
  onlineService,
  videoService,
}

export interface ISourceSetting {
  bizType: BizType;
}

export interface IGetReceiptToken {
  companyId: number;
  expiredAt: string;
  source: string;
  token: string;
  type: string;
  visitorId: string;
  visitorName: string;
}

export type CommonObject = {
  [propName: string]: any;
};

export interface OssImageInfo {
  FileSize: {
    value: string;
  };
  Format: {
    value: string;
  };
  ImageHeight: {
    value: string;
  };
  ImageWidth: {
    value: string;
  };
}

export enum SessionOverWay {
  CustomerOver = 1,
  ExitQueue = 2,
}

/** 关闭会话 */
export interface SessionOprParams {
  /** 无意义的 sourceId */
  sourceId?: string;
  /** 会话服务 id */
  conversationServiceId?: string;
  /** 会话 id */
  conversationId?: string;
  msgId?: string | number;
  /**
   * 访客自行点击结束排队导致会话结束为1，主动退出排队为2，其他或为空代表其他途径关闭
   */
  line?: SessionOverWay;
}
/** 非安卓，ios，非小程序 webview 就认为是 web 端*/
/** 兼容 pad 使用 h5 */
export const isWeb =
  (window.innerWidth ? window.innerWidth > 768 : !UA.isAndroid() && !UA.isIOS()) &&
  !UA.isWeappWebview();

export const isZLAppOrMini = () => {
  // sUserAgent.indexOf('dtdreamweb') > -1 // 浙里办APP
  // sUserAgent.indexOf('miniprogram') > -1 && sUserAgent.indexOf('alipay') > -1 // 浙里办支付宝小程序
  const sUserAgent = window.navigator.userAgent.toLowerCase();
  return (
    sUserAgent.indexOf('dtdreamweb') > -1 ||
    (sUserAgent.indexOf('miniprogram') > -1 && sUserAgent.indexOf('alipay') > -1)
  );
};

export enum UiStyle {
  /** 常规版 */
  normal = 'normal',
  /** 老年版 */
  elder = 'elder',
}

/** sentry 提示 */
export const EMPTY_INFO = '无或该数据还没生成';
export interface GetKnowledgeParams {
  /** 会话id */
  sourceId?: string;
  /** 对话 */
  message?: string;
  /** 是否需要标题高亮 */
  highLight?: boolean;
  /** 高亮的颜色 */
  color?: string;
  /** 需要匹配度前几个 */
  limit?: number;
}

export interface GetKnowledgeResponse {
  /** 会话id */
  conversationId: string;
  /** 会话服务id */
  conversationServiceId: string;
  /** 话术id */
  robotId: number;
  /** 内容 */
  content: string;
  /** 附件 */
  attachment: string;
  /** 知识库id */
  knowledgeBaseId: number;
  /** 标题 */
  title: string;
  /** 标准问的 id */
  replySceneId: string;
  /** dev知识库主题id */
  knowledgeThemeId: number;
  /** 知识库标准问题 */
  standardQuestion: string;
  /** 知识库回答内容 - 附件信息 */
  extraInfo: string;
  highlightTitle: string;
}

export enum InputType {
  VOICE = 0,
  TEXT = 1,
}

export interface VoiceTrTextParams {
  /** 会话id */
  conversationServiceId?: string;
  /** 录音连接 */
  url: string;
  /** 消息id */
  msgId?: number;
  /** 连接 */
  connectionId?: string;
}

export interface VoiceTrTextResponse {
  /** 录音文件地址 */
  voiceUrl: string;
  /** 文字 */
  text: string;
}

export type IFuncLike = (...args: any[]) => any;

export type ILinkFuncProps = {} & Partial<HTMLAnchorElement>;
