import { IdentityOCRType } from '@/declare/feedback';
import { ReceiptDetailData, ReceiptNoticeData } from './receipt';
import { IdentityAuthDetailData, IdentityAuthNoticeData } from './identity';

/**
 * 登录成功响应消息
 */
export interface ILoginSuccessResponse {
  code: number;
  data: {
    connectionId: string;
  };
}

export enum MsgType {
  // 文本
  TEXT = 'text',
  // 图片
  IMAGE = 'image',
  // 位置
  LOCATION = 'location',
  // 文件
  FILE = 'file',
  // 视频
  VIDEO = 'video',
  // 音视频通话消息
  LIVE = 'live',
  //
  SCHEDULE = 'schedule',
  // 系统通知 - 后端向前端/移动端发送个事件消息
  NOTICE = 'notice',
  // 后端发给前端执行的动作
  ACTION = 'action',
  // 获取消息
  // 满意度反馈
  SATISFY = 'satisfy',
  // 排队超时提示
  WAITING_TIMEOUT_TIP = 'waiting_timeout_tip',
  // 链接
  LINK = 'link',
  // 回单通知
  RECEIPT_NOTICE = 'feedback_notice',
  // 回单详情
  RECEIPT_DETAIL = 'feedback_detail',
  // 认证通知
  IDENTITY_NOTICE = 'identity_auth_notice',
  // 认证详情
  IDENTITY_DETAIL = 'identity_auth_detail',
  // OCR识别信息
  OCR_DETAIL = 'ocr_detail',
  // OCR识别提示
  OCR_NOTICE = 'ocr_notice',
  // 前端发送智能收件
  FeedbackAutomatic = 'feedback_automatic',
  // 语音类型
  VOICE = 'voice',
  // 更新寄件类型
  FEEDBACK_POST_NOTICE = 'feedback_post_notice',
}

export const MsgTypeText = {
  [MsgType.IMAGE]: '图片',
  [MsgType.FILE]: '文件',
  [MsgType.LOCATION]: '位置',
  [MsgType.VIDEO]: '视频',
};

/**
 * 机器人客服满意度
 */
export enum SatisfyStatus {
  notNeedComment = -1,
  needComment = 0,
  good = 1,
  bad = 2,
}

/**
 * 人工客服满意度
 */
export enum ServiceSatisfyStatus {
  notComment,
  satisfied,
  normal,
  dissatisfied,
}

export enum ExtraInfoType {
  link = 'link',
  reply = 'reply',
  text = 'TEXT',
}

export enum AttachmentType {
  document = 'DOCUMENT',
  video = 'VIDEO',
  text = 'TEXT',
  address = 'ADDRESS',
  /** 下面内容是chatbot机器人中返回内容 */
  file = 'FILE',
  image = 'IMAGE',
}

export interface IMsgExtraInfo {
  type: ExtraInfoType;
  title: string;
  replySceneId?: string;
  content: string;
  contents?: any[];
}

export interface IMsgAttachment {
  type: AttachmentType;
  contents: any[];
  content?: string;
}

export interface IPeerReadContent extends IMsgContent {
  noticeType: string;
  readMark: number;
}

export interface IMsgContent {
  conversationId: string;
  conversationServiceId?: string;
  isSelf: boolean;
  isRobot: boolean;
  msgId: number;
  content: string;
  action?: string;
  quoteMess?: ChatItem;
}

export type ChatItemContent = IMsgContent | IPeerReadContent;

export type IReceiptContent = IMsgContent & {
  event?: string;
  formId: string;
};

/**
 * 服务端返回的消息数据
 */
export type ChatItem = (
  | {
      msgType: Exclude<MsgType, MsgType.NOTICE>;
      content: IMsgContent;
    }
  | {
      msgType: MsgType.NOTICE;
      content: IPeerReadContent;
    }
  | {
      msgType: MsgType.RECEIPT_NOTICE;
      content: IReceiptContent;
    }
) & {
  to: string;
  from: string;
  nickname: string;
  avatar: string;
  extId: string;
  extName: string;
  extAvatar: string;
  read: boolean;
  createTime: number;
  revoke: boolean;
  msgId?: number;
  isRevoke?: boolean;
  quoteMess?: ChatItem;
  operationType?: operationTypeEnum;
};

export enum operationTypeEnum {
  '没有操作',
  '点击继续等待',
  '点击结束会话',
  '系统结束排队',
}
/**
 * MsgContent中的content字符串parse后得到
 */
// 文本数据
export interface TextData {
  text: string;
}

// 图片数据
export interface ImageData {
  url: string;
}

// 文件数据
export interface FileData {
  url: string;
  title: string;
  fileSize: number;
  type: string;
  unit?: string;
}

// 链接
export interface LinkData {
  link: {
    url: string;
    text: string;
    title: string;
  }[];
}

// 位置数据
export interface LocationData {
  name: string;
  address: string;
  shotUrl: string;
  latitude: string;
  longitude: string;
}

// 视频通话消息数据
export interface LiveData {
  action: LiveAction;
  transactionId: string;
  liveType: string;
  token: string;
  channel: string;
  appId: string;
  uid: string;
}

export interface VideoData {
  recordUrl: string;
}

export interface TextToVoiceData {
  textTrVoiceUrl: string[];
  isWelcome: boolean;
}

export interface OcrDetailData {
  noticeBy: string;
  ocrType: IdentityOCRType;
}

export interface OcrNoticeData {
  ocrType: IdentityOCRType;
  event: OcrNoticeEvent;
  updateTime: number;
}

export interface FeedbackPostNoticeData {
  businessNo: number;
  instanceId: number;
}

export enum OcrNoticeEvent {
  PUSH = 'ocr_push',
  COMMIT = 'ocr_commit',
}

export const OcrNoticeEventText: Record<OcrNoticeEvent, string> = {
  [OcrNoticeEvent.PUSH]: '推送了',
  [OcrNoticeEvent.COMMIT]: '完成了',
};

/// 显示为灰色的调度类型
type GarySchedule =
  | 'csClosed'
  | 'reception'
  | 'serviceTransferContent'
  | 'sessionStart'
  | 'receptionistOffWork';

// 显示为白色的调度类型
type WhiteSchedule = 'waiting' | 'timeoutNoReply' | 'noReceptionistOnline' | 'noReceptionistBusy';

// 调度消息
export interface ScheduleData {
  text: string;
  action: GarySchedule | WhiteSchedule;
}

export type MsgFormat<T extends MsgType, K> = Omit<ChatItem, 'msgType' | 'content'> & {
  msgType: T;
  content: K;
};

export type Message =
  | MsgFormat<MsgType.TEXT, TextData>
  | MsgFormat<MsgType.IMAGE, ImageData>
  | MsgFormat<MsgType.FILE, FileData>
  | MsgFormat<MsgType.LOCATION, LocationData>
  | MsgFormat<MsgType.RECEIPT_DETAIL, ReceiptDetailData>
  | MsgFormat<MsgType.RECEIPT_NOTICE, ReceiptNoticeData>
  | MsgFormat<MsgType.IDENTITY_DETAIL, IdentityAuthDetailData>
  | MsgFormat<MsgType.IDENTITY_NOTICE, IdentityAuthNoticeData>
  | MsgFormat<MsgType.LIVE, LiveData>
  | MsgFormat<MsgType.NOTICE, undefined>
  | MsgFormat<MsgType.ACTION, string>
  | MsgFormat<MsgType.SCHEDULE, ScheduleData>
  | MsgFormat<MsgType.LINK, LinkData>
  | MsgFormat<MsgType.VIDEO, VideoData>
  | MsgFormat<MsgType.OCR_DETAIL, OcrDetailData>
  | MsgFormat<MsgType.OCR_NOTICE, OcrNoticeData>
  | MsgFormat<MsgType.FEEDBACK_POST_NOTICE, FeedbackPostNoticeData>;

export enum RobotType {
  '外呼机器人' = '外呼机器人',
  '文本机器人' = '文本机器人',
}
export interface IParsedContent {
  action?: string;
  text: string;
  // 机器人回答满意度
  satisfyStatus: SatisfyStatus;
  // 是否显示转人工
  showTransferCsButton: boolean;
  // 机器人回答额外信息
  extraInfo: IMsgExtraInfo[];
  // 附件
  attachment: IMsgAttachment[];
  msgId: number;
  content?: string;
  transactionId?: string;
  conversationServiceId?: string;
  isWelcome?: boolean;
  link?: string[];
  noticeType: string;
  robotType?: RobotType;
  userSay?: string;
}

/**
 * 格式化后的消息
 */
export interface ILiveChatItem extends Omit<ChatItemContent, 'content'> {
  key?: string;
  visitorId?: string;
  msgType: MsgType;
  name: string;
  avatar: string;
  updateTime?: number;
  content: Partial<IParsedContent> | string;
  read?: boolean;
  isRevoke?: boolean;
  // 是否为欢迎语
  isFirstWelcome?: boolean;
  from?: string;
  to?: string;
}

// 通话类型
export enum LiveType {
  VIDEO = 'video',
  AUDIO = 'audio',
}

export enum LiveAction {
  /** 发起通话 */
  StartLiveCall = 'startLiveCall',
  /** 通话拒接/取消 */
  CancelCall = 'cancelCall',
  /** 退出通话 */
  LeaveCall = 'leaveCall',
  /** 转接通话 */
  TransferCall = 'transferCall',
  /** 视频通话记录 */
  LiveLog = 'liveLog',
  /** 通话失败 */
  CallFailed = 'call_failed',
  /** 通话超时 */
  TimeoutCall = 'timeoutCall',
}

export enum RobotReplayType {
  TEXT = 'TEXT',
  LINK = 'LINK',
  IMAGE = 'IMAGE',
  FILE = 'FILE',
}

export enum RecordState {
  NORMAL = 0,
  RECORDING,
  LISTENING,
}
