import { EMPTY_INFO } from '@/declare';
import store from '@/store';
import * as Sentry from '@sentry/react';
import { mapValues } from 'lodash';
import { flatObj, urlParam } from '.';

/** 格式化一下额外数据 */
export const formatExtraInfo = (extra: Record<string, unknown>) => {
  return mapValues(
    {
      __userAgent: window.navigator.userAgent,
      __visitorId: store?.sceneStore?.userId || EMPTY_INFO,
      __sourceId: urlParam()?.sourceId,
      ...extra,
    },
    (val) => flatObj(val),
  );
};

/** 手动向 sentry 上报错误 */
export const reportError = (error: any, extra: Record<string, unknown>) => {
  Sentry.captureException(error, {
    extra: formatExtraInfo(extra),
  });
};

/** 向 sentry 上传日志信息 */
export const reportLog = (msg: string, extra: Record<string, unknown>) => {
  msg += `-${Date.now()}`;
  Sentry.captureMessage(msg, { extra: formatExtraInfo(extra) });
};
