import {
  ISendMessageResponse,
  IFetchChatListRequestParams,
  IAiConfigInfo,
  IVisitorWelcomeResponse,
  ChatItem,
  ISatisfyRobotReplyParams,
  ISatisfyServiceParams,
  ISourceSetting,
  IGetReceiptToken,
  FetchSessionStatusParams,
  ReceptionistServingRes,
  SessionOprParams,
  GetKnowledgeParams,
  GetKnowledgeResponse,
  VoiceTrTextParams,
  VoiceTrTextResponse,
} from '@/declare/index';
import Ajax, { promiseRequest } from '@/utils/ajax';
import { ExpressInfo, ReceiptData } from '@/declare/receipt';
import { IConfirmFeedbackParams, ISaveFeedback } from '@/declare/feedback';
import {
  IGetOCRResult,
  OCRIdentityResultType,
  ISubmitOCRResult,
  IdentityAuthResult,
} from '@/declare/identity';
import store from '@/store';

let tokenInfo;

/**
 * 获取欢迎语
 * @param params
 */
export const getVisitorWelcome = (params) =>
  Ajax.get<IVisitorWelcomeResponse>(`/api/courier/setting/autoAnswer/getVisitorWelcome`, params);

/**
 * 获取问题引导、功能引导等配置信息
 * @param sourceId
 */
export const getAiConfigInfo = (sourceId: string) =>
  Ajax.get<IAiConfigInfo>('/api/courier/visitor/endpoint/source/setting/getAiConfigInfo', {
    sourceId,
  });

/**
 * @name  访客端查询会话是否活跃
 * @接口负责人 baishao
 * @url https://apidoc.indata.cc/project/273/interface/api/27436
 */
export const fetchSessionStatus = (params: FetchSessionStatusParams) =>
  Ajax.get<ReceptionistServingRes>('/api/courier/conversation/receptionist/serving', params);

export const fetchWaittingId = () => Ajax.get<number>('/api/courier/message/waiting/msgId');

/**
 * 获取网站设置
 * @param sourceId
 */
export const getSourceSetting = (sourceId: string) =>
  Ajax.get<ISourceSetting>('/api/courier/source/setting/getSourceSetting', { sourceId });

/**
 * 获取历史消息
 * @param params
 */
export const getHistoryList = (params: IFetchChatListRequestParams) =>
  Ajax.get<ChatItem[]>(`/api/courier/message/visitor/get`, params);

/**
 * 发送消息
 * @param connectionId
 * @param sourceId
 * @param message
 */
export const sendMessage = (connectionId, sourceId, message) =>
  Ajax.post<ISendMessageResponse>(
    `/api/courier/message/visitor/${connectionId}/send?sourceId=${sourceId}`,
    message,
  );

/**
 * 机器人回复满意度评价
 * @param params
 */
export const satisfyRobotReply = (sourceId: string, params: ISatisfyRobotReplyParams) =>
  Ajax.post(`/api/courier/satisfy/robotReply?sourceId=${sourceId}`, params);

/**
 * 获取地址截图
 * @param longitude
 * @param latitude
 */
export const getLocationShotUrl = (longitude: string, latitude: string) =>
  Ajax.get('/livechat/geo/thumbnail/amap', {
    location: `${longitude},${latitude}`,
  });

/**
 * 未读消息清理
 * @param conversationId
 */
export const cleanUnreadSignal = (sourceId: string, conversationId: string) =>
  Ajax.get('/api/courier/conversation/unread/cleanSingle', { sourceId, conversationId });

/**
 * 人工客服满意度评价
 * @param sourceId
 * @param params
 */
export const satisfyService = (sourceId: string, params: ISatisfyServiceParams) =>
  Ajax.post(`/api/courier/satisfy/comment?sourceId=${sourceId}`, params);

/**
 * 上传base64图片到oss
 * @param base64 base64图片
 * @param path 上传地址
 */
export const base64ToOss = (base64: string, path: string) =>
  promiseRequest(
    'https://oss.byrobot.cn/oss/base64',
    {
      bucket: 'by-fe-cdn',
      base64,
      path,
    },
    'POST',
    {
      'content-type': 'application/json;charset=UTF-8',
    },
  );

/**
 * 获取微信授权地址
 */
export const getWebOauthUrl = (sourceId: string) =>
  Ajax.get('/api/courier/wxcp/webOauth/url', { sourceId });

// 查看回单详情
export const getReceiptFormDetail = (formId: number, token: string) =>
  Ajax.get<ReceiptData>(`/api/feedback/flows/form/${formId}`, {}, { 'feedback-token': token });

// 获取回单token
export const getReceiptToken = async (typeParam?: string, infoIdParam?: string) => {
  const type = typeParam ?? 'visitor';
  const infoId = infoIdParam ?? store.sceneStore.userId;
  const { token, expiredAt } = tokenInfo || {};
  if (token && expiredAt > Date.now()) {
    return token;
  }
  const res = await Ajax.get<IGetReceiptToken>(`/api/feedback/token`, {
    type,
    infoId: encodeURIComponent(infoId),
    source: 'courier',
  });
  console.log(res, '回单 token info');

  tokenInfo = {
    token: res.token,
    expiredAt: new Date(res.expiredAt).getTime(),
  };
  return res.token;
};

// 确认签名
export const confirmReceiptSign = (params: IConfirmFeedbackParams) =>
  Ajax.post(
    `/api/feedback/flows/form/sign?connectionId=${params.connectionId}`,
    { ...params },
    { 'feedback-token': params.token },
  );

// 获取身份认证链接
export const getIdentityAuthInfo = (authId: number, token: string, redirectUrl?: string) =>
  Ajax.get<{ authLogId: string; url: string }>(
    `/api/feedback/auth/${authId}/authLink`,
    { redirectUrl },
    { 'feedback-token': token },
  );

// 轮询认证结果
export const getIdentityAuthResult = (authLogId: number, token: string) =>
  Ajax.get<IdentityAuthResult>(
    `/api/feedback/auth/result?authLogId=${authLogId}`,
    {},
    { 'feedback-token': token },
  );

// 获取个人签章
export const getPersonalSign = (instanceId: number, token: string) =>
  Ajax.put<string>(
    `/api/feedback/flows/person/seal/${instanceId}`,
    {},
    { 'feedback-token': token },
  );

export const saveFeedback = (params: ISaveFeedback) =>
  Ajax.put(
    `/api/feedback/flows/visitor/save/feedback?connectionId=${params.connectionId}`,
    params,
    { 'feedback-token': params.token },
  );

export const getOCRIdentityResult = (params: IGetOCRResult) =>
  Ajax.form<OCRIdentityResultType>(
    '/api/feedback/ocr/result',
    {
      data: params.data,
    },
    {
      'feedback-token': params.token,
    },
  );

export const SubmitOCRResult = (params: ISubmitOCRResult) =>
  Ajax.form('/api/feedback/ocr/commit', params, {
    'feedback-token': params.token,
  });

/**
 * @name  会话服务关闭接口(客服工作台)
 * @接口负责人 leduo
 * @url https://apidoc.indata.cc/project/273/interface/api/5179
 */
export const closeConversationService = (params: SessionOprParams): Promise<boolean> => {
  const { sourceId, ...data } = params;
  return Ajax.post(
    `/api/courier/conversation/conversationService/visitor/close?sourceId=${sourceId}&`,
    data,
  );
};
/**
 * 获取排队人数
 * @param param0 会话id
 * @returns 排队人数
 */
export const getWaitingCount = ({ conversationServiceId }): Promise<number> => {
  return Ajax.get(
    `/api/courier/visitor/getWaitingNum?conversationServiceId=${conversationServiceId}`,
  );
};
/**
 * @name  访客继续排队
 * @接口负责人 baishao
 * @url https://apidoc.indata.cc/project/273/interface/api/29322
 */
export const continueWaiting = (params: SessionOprParams): Promise<boolean> => {
  const { sourceId, ...data } = params;
  return Ajax.get(`/api/courier/visitor/continue/waiting?sourceId=${sourceId}`, data);
};

/**
 * @name  根据意图查找文本机器人的知识库(访客)
 * @接口负责人 baishao
 * @url https://apidoc.indata.cc/project/273/interface/api/31094
 */
export const getKnowledge = (params: GetKnowledgeParams) =>
  Ajax.post<GetKnowledgeResponse[]>('/api/courier/message/visitor/get/knowledge', params);

/**
 * @name  语音转文字(访客)
 * @接口负责人 baishao
 * @url https://apidoc.indata.cc/project/273/interface/api/31520
 */
export const voiceTrText = (params: VoiceTrTextParams) =>
  Ajax.post<VoiceTrTextResponse>('/api/courier/message/voice/trText', params);

/**
 * @name  查询已邮寄的信息列表
 * @接口负责人 baishao
 * @url https://apidoc.indata.cc/project/538/interface/api/32795
 */
export const getExpressList = async (params: { instanceId: number }) => {
  const token = await getReceiptToken();
  return Ajax.get('/api/feedback/flows/post/list', params, {
    'feedback-token': token,
  }) as Promise<ExpressInfo[] | false>;
};
