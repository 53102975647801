import { IParsedContent, LiveAction, LiveType, MsgType, isWeb } from '@/declare';
import { reportError } from '@/utils';
import { action, observable } from 'mobx';
import message from 'antd/lib/message';
import 'antd/lib/message/style/index.css';
// import { liveGlobalEvent, liveEvents } from '@indata/awe-video-chat';
import 'antd/lib/modal/style/css.js';
import { createTrack, fillTrack } from '@/utils/uploader/promise-track';

import { RootStore } from './index';

export class VideoStore {
  @observable videoChatVisible: boolean = false;
  @observable transactionId: string = '';
  @observable videoData: any = {};
  @observable isInVideo: boolean = false;
  @observable cameraAuth: boolean = true;
  @observable microphoneAuth: boolean = true;

  @action
  toggleVideo = (show: boolean) => {
    this.videoChatVisible = show;
  };

  @action
  setVideoData = (data: any) => {
    this.videoData = data;
    this.transactionId = data.transactionId;
  };

  @action
  resetVideoData = () => {
    this.videoData = {};
    this.transactionId = '';
    this.videoChatVisible = false;
    this.isInVideo = false;
  };

  @action
  handleLiveMsg = async (msgContent: IParsedContent) => {
    const { liveGlobalEvent, liveEvents } = await import('@indata/awe-video-chat');
    const { action: liveAction, transactionId } = msgContent;
    // 可能存在上次的会话延迟收到的消息，需要判断当前事务id与收到的事件的事务id进行对比过滤【对不匹配的事务id事件不做响应处理】
    if (this.transactionId && transactionId !== this.transactionId) return;

    switch (liveAction) {
      // 访客端发起视频通话请求
      case LiveAction.StartLiveCall:
        this.setVideoData(msgContent);
        this.toggleVideo(true);
        break;
      case LiveAction.CancelCall:
        this.resetVideoData();
        liveGlobalEvent.emit(liveEvents.VIDEO_CHAT_LEAVE);
        message.warn('对方已拒绝');
        break;
      case LiveAction.CallFailed:
        this.resetVideoData();
        liveGlobalEvent(liveEvents.VIDEO_CHAT_LEAVE);
        reportError(new Error('访客--视频通话失败'), { msgContent });
        break;
      case LiveAction.TimeoutCall:
        this.resetVideoData();
        liveGlobalEvent(liveEvents.VIDEO_CHAT_LEAVE);
        message.warn('呼叫超时');
        break;
      default:
        break;
    }
  };

  async getMediaAuth() {
    const playerList: MediaStreamConstraints[] = [{ video: true }, { audio: true }];
    const track = createTrack(2);
    const promiseCreator = async (player: MediaStreamConstraints) => {
      try {
        await navigator.mediaDevices.getUserMedia(player);
        return true;
      } catch (error) {
        return false;
      }
    };
    const {
      successList: [cameraAuth, microphoneAuth],
    } = await fillTrack(track, playerList, { promiseCreator });
    return {
      cameraAuth,
      microphoneAuth,
    };
  }

  @action
  handleStartVideoCall = () => {
    if (this.videoChatVisible) {
      message.warn('正在通话中，请结束后再试');
      return;
    }
    if (this.isInVideo) {
      message.warn('正在请求通话中，请稍后再试');
      return;
    }
    const startLive = () => {
      this.isInVideo = true;
      console.log(this.root.ws, '=-=-=-=-=-=');
      this.root.ws.sendMessage(
        {
          msgType: MsgType.LIVE,
          content: {
            action: LiveType.VIDEO,
          },
        },
        () => {
          setTimeout(() => {
            this.isInVideo = false;
          }, 5000);
        },
        () => {
          this.isInVideo = false;
        },
      );
    };

    this.getMediaAuth().then(({ cameraAuth, microphoneAuth }) => {
      this.cameraAuth = cameraAuth;
      this.microphoneAuth = microphoneAuth;
      startLive();
    });
    // .catch(() => {
    //   this.cameraAuth = false;
    //   startLive();
    //   // Modal.confirm({
    //   //   title: '当前设备未检测到摄像头或摄像头权限未开启，是否使用屏幕共享功能？',
    //   //   onOk: startLive,
    //   //   okText: "确认",
    //   //   cancelText: "取消",
    //   //   onCancel: async () => {
    //   //     message.warn('已取消通话');
    //   //   },
    //   // });
    // });
  };

  root: RootStore;
  constructor(root: RootStore) {
    this.root = root;
  }
}

export default VideoStore;
