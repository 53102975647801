import { IdentityAuthResult, IdentityInfo } from '@/declare/identity';
import { action, observable } from 'mobx';
import { RootStore } from '.';

export class FeedbackStore {
  @observable signatureUrl: string = '';

  @observable ossUrl: string = '';

  @observable formId: string = '';

  @observable authUrl: string = '';

  @action
  setSignatureUrl = (url: string) => {
    this.signatureUrl = url;
  };

  @action
  setOssUrl = (url: string) => {
    this.ossUrl = url;
  };

  @action
  setFormId = (id: string) => {
    this.formId = id;
  };

  @action
  setAuthUrl = (url: string) => {
    this.authUrl = url;
  };

  defaultIdentityInfo: IdentityInfo = {
    result: IdentityAuthResult.notStart,
    isFinished: false,
    url: '',
    authPageVisible: true,
  };
  @observable identityInfo: IdentityInfo = this.defaultIdentityInfo;

  @action setIdentityInfo = (identityInfo: Partial<IdentityInfo>) => {
    this.identityInfo = { ...this.identityInfo, ...identityInfo };
  };

  @action resetIdentityInfo = () => {
    this.identityInfo = this.defaultIdentityInfo;
  };

  root: RootStore;
  constructor(root: RootStore) {
    this.root = root;
  }
}

export default FeedbackStore;
