import { CourierSourceType } from '@/declare/user';
import { urlParam } from '@/utils';
import { action, observable } from 'mobx';
import { RootStore } from '.';

interface IUrlParams {
  sourceId: string;
  metadata: string;
  /**
   * 是否直接放开链接
   * 即无论在哪个环境点击链接都直接跳转页面
   * */

  linkOpen?: string;
  code: string;
  showCopyright?: string;
}

export class SceneStore {
  @observable imToken: string = '';

  @observable userId: string = '';

  @observable urlParams: IUrlParams = {
    sourceId: '',
    metadata: '',
    linkOpen: '',
    code: '',
  };

  @observable courierSourceType: CourierSourceType = CourierSourceType.H5;

  @action setCourierTokenType = (courierSourceType: number) => {
    this.courierSourceType = courierSourceType;
  };

  @action
  setToken(token: string) {
    this.imToken = token;
  }

  @action
  setUserId(userId: string) {
    this.userId = userId;
  }

  @action
  setUrlParams = (params: IUrlParams) => {
    this.urlParams = params;
  };

  root: RootStore;
  constructor(root: RootStore) {
    // 初始化 urlParams
    this.urlParams = urlParam();
    this.root = root;
  }
}

export default SceneStore;
