import { ApplicationKey, AutomaticListItem } from '@/declare/intelligent-receipt';
import { getApplicationOpenStatus } from '@/service/intelligent-receipt';
import { action, observable } from 'mobx';
import { RootStore } from '.';

export class PermissionStore {
  /** 是否处于智能客服接待 */
  @observable isAIReception: boolean = false;

  /** 是否开通退出排队服务 */
  @observable isExitQueue: boolean = false;

  /** 是否处于排队中 */
  @observable waittingInfo: Record<string, any> = { isWaitting: false };

  @action setIsAIReception = (isAIReception: boolean) => {
    this.isAIReception = isAIReception;
  };

  /** 智能收件按钮的权限点 -- 与 saas 端应用中心的智能收件开通挂钩  */
  @observable intelligentReceiptPermission: boolean = false;

  // 用户自定义是否打开智能收件按钮
  @observable customReceiptPermission: boolean = false;

  // 用户选择展示的智能收件数组
  @observable customReceiptOptionList: AutomaticListItem[] = [];

  @action setCustomReceiptOptionList = (customReceiptOptionList: AutomaticListItem[]) => {
    this.customReceiptOptionList = customReceiptOptionList;
  };

  @action setCustomReceiptPermission = (autoMaticSwitch: boolean) => {
    this.customReceiptPermission = autoMaticSwitch;
  };

  @action setWaitting = (info: Record<string, any>) => {
    this.waittingInfo = info;
  };

  @action setIntelligentReceiptPermission = (intelligentReceiptPermission: boolean) => {
    this.intelligentReceiptPermission = intelligentReceiptPermission;
  };

  @action setIsExitQueuePermission = (permission: boolean) => {
    this.isExitQueue = permission;
  };

  @action fetchIntelligentReceiptPermission = (appKey: ApplicationKey, sourceId: any) => {
    getApplicationOpenStatus(appKey, sourceId).then((res) => {
      this.setIntelligentReceiptPermission(res);
    });
  };

  /*----------------- 文字转语音播报 -----------------*/
  @observable textTrVoicePermission: boolean = false;

  @action setTextTrVoicePermission = (textTrVoicePermission: boolean) => {
    this.textTrVoicePermission = textTrVoicePermission;
  };

  root: RootStore;
  constructor(root: RootStore) {
    this.root = root;
  }
}

export default PermissionStore;
