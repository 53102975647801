import { ApplicationKey, AuthCreateParams, AutomaticListItem } from '@/declare/intelligent-receipt';
import Ajax from '@/utils/ajax';
import { AxiosRequestConfig } from 'axios';

/**
 * @name  智能收件列表(访客端展示用)
 * @接口负责人 baishao
 * @url https://apidoc.indata.cc/project/538/interface/api/25716
 */
export const fetchAutomaticList = ({ headers }: AxiosRequestConfig) =>
  Ajax.get<AutomaticListItem[]>('/api/feedback/automatic/list', {}, headers);

/** @name 查询单个应用开通状态 */
export const getApplicationOpenStatus = (appKey: ApplicationKey, sourceId: any) =>
  Ajax.get<boolean>('/api/courier/plugin/queryOpenStatus', { appKey, sourceId });

/**
 * @name  新建身份认证
 * @接口负责人 baishao
 * @url https://apidoc.indata.cc/project/538/interface/api/26308
 */
export const authCreate = (params: AuthCreateParams) => {
  const { feedbackToken, ...data } = params;
  return Ajax.post<boolean>('/api/feedback/auth/create', data, { 'feedback-token': feedbackToken });
};
