/*
 * @Description: 重写 pushState 监听 taro 路由跳转，如果非 app.config.ts pages 页面的路由就跳转 404
 */

/** 函数式调用 link */
const link = ({ ...linkNodeProps }: {} & Partial<HTMLAnchorElement>) => {
  const a = document.createElement('a');
  a.setAttribute('style', 'display:none');
  for (const key in linkNodeProps) {
    a[key] = linkNodeProps[key];
  }
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

/** 跳转到 404 */
const redirectTo404 = (url: any) => {
  /** 未找到匹配的路由，去 404 页面 */
  const matchPath = process.env.APP_CONFIG.pages.find((path) => url?.toString()?.includes(path));
  if (!matchPath) {
    link({ href: '/pages/404/index' });
  }
};

/** 重写 pushState， taro3.0 history 路由基于 pushState */
window.history.pushState = ((push) => {
  return function (...args: Parameters<typeof push>) {
    const [_, __, url] = args;
    push.apply(this, args);
    redirectTo404(url);
  };
})(window.history.pushState);

/** 第一次进看一下当前是否需要跳 404 */
redirectTo404(window.location.pathname);

/** 纯做导出 */
export function rewriteOk() {
  console.log('rewriteOk！');
}
