import React from 'react';
import WSStore from './ws/index';
import VideoStore from './video';
import FeedbackStore from './feedback';
import SceneStore from './scene';
import PermissionStore from './permission';
import ThemeStore from './theme';

export class RootStore {
  ws: WSStore;
  sceneStore: SceneStore;
  videoStore: VideoStore;
  feedbackStore: FeedbackStore;
  permissionStore: PermissionStore;
  themeStore: ThemeStore;

  constructor() {
    this.sceneStore = new SceneStore(this);
    this.videoStore = new VideoStore(this);
    this.feedbackStore = new FeedbackStore(this);
    this.permissionStore = new PermissionStore(this);
    this.themeStore = new ThemeStore(this);
    this.ws = new WSStore(this);
  }
}

const store = new RootStore();

const storesContext = React.createContext(store);
export const useStores = () => React.useContext(storesContext);
export default store;
