import React, { Component } from 'react';
import 'taro-ui/style/index.scss';
import { isZLAppOrMini, UiStyle } from '@/declare';
import { Provider } from 'mobx-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import '@/indata/angel-component/style/index.scss';
import ThemeBtn from './components/theme-btn';
import { AI_STYLE, Theme } from './store/theme';
import './app.less';
import './byicon.css';
import store from './store';
import SentryBtn from './components/sentry-btn';
import { formatExtraInfo, REAL_ENV, SessionStorage, SessionStorageKey } from './utils';
import { rewriteOk } from './utils/redirect404';
import '@nutui/nutui-react-taro/dist/style.css';
import VConsole from 'vconsole';

rewriteOk();

if (process.env.NODE_ENV !== 'production') {
  new VConsole();
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    // 注册项目的 dsn
    dsn: 'https://bf2c265dc14041a2b72c175232311c41@sentry.byai.com/10',
    integrations: [new Integrations.BrowserTracing()],
    // 是否上报
    enabled: true,
    // TODO: 暂时用端口号区分是否是本地环境 sentry 上报的环境名
    environment: REAL_ENV,
    release: process.env.release,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const sendPv = () => {
  window.ZWJSBridge.getLocation()
    .then((result) => {
      const { longitude, latitude } = result;
      window.aplus_queue.push({
        action: 'aplus.sendPV',
        arguments: [
          { is_auto: false },
          {
            // ⾃定义PV参数key-value键值对（只能是这种平铺的json，不能做多层嵌套）， 如：
            miniAppId: '2001929044',
            miniAppName: '德清公安在线客服',
            long: longitude,
            lati: latitude,
          },
        ],
      });
    })
    .catch((error) => {
      console.log(error);
    });
  window.ZWJSBridge.getUserType()
    .then((result) => {
      const { userType } = result;
      window.aplus_queue.push({
        action: 'aplus.sendPV',
        arguments: [
          { is_auto: false },
          {
            userType: userType,
          },
        ],
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

type IState = {};

type IAppProps = {
  userData: { userId: string };
};
class App extends Component<IAppProps, IState> {
  componentDidMount() {
    // TODO: 默认先以长辈版进入页面，后续接入浙里办 api 再做动态,pv日志参数埋点
    const { className } = document.body;
    let theme = Theme.Normal;

    if (isZLAppOrMini()) {
      window.ZWJSBridge?.onReady(() => {
        // 获取是长辈办还是标准版,后续考虑下面result是否去声明类型，以及写法的简化
        sendPv();
        window.ZWJSBridge.getUiStyle()
          .then((result) => {
            const { uiStyle } = result;
            theme = uiStyle === UiStyle.normal ? Theme.Normal : Theme.OldPerson;
            store.themeStore.setTheme(theme);
            document.body.className = className + ' ' + theme;
          })
          .catch((error) => {
            console.error(error);
          });
      });
    } else {
      // 使用 sessionStorage 获取智慧导服使用 a 标签跳转来的值
      theme = SessionStorage.getData<Theme>(SessionStorageKey.Theme) || Theme.Normal;
      store.themeStore.setTheme(theme);
      document.body.className = className + ' ' + theme;
    }

    // TODO: 用户自定义主题色 ，先用默认颜色，后续由接口拿
    store.themeStore.setThemeStyle(AI_STYLE);
  }

  componentDidCatch(error: any, info: any) {
    // 第二个参数可设置一些额外信息
    Sentry.captureException(error, {
      user: { id: this.props.userData && this.props.userData.userId },
      extra: formatExtraInfo({}),
    });
  }

  componentDidShow() {}

  componentDidHide() {}

  // 在 App 类中的 render() 函数没有实际作用
  // 请勿修改此函数
  render() {
    return (
      <Provider store={store}>
        {process.env.NODE_ENV !== 'production' && <SentryBtn />}
        {process.env.NODE_ENV !== 'production' && <ThemeBtn />}
        {this.props.children}
      </Provider>
    );
  }
}

export default App;
