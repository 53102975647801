import { isColorLight } from '@/utils/color';
import { CSSProperties } from 'hoist-non-react-statics/node_modules/@types/react';
import { action, computed, observable } from 'mobx';
import { RootStore } from '.';

export enum Theme {
  OldPerson = 'theme-old-person',
  Normal = 'theme-normal',
}

export const AI_STYLE = {
  color: 'rgba(255, 255, 255, 0.65)',
  backgroundColor: '#2089f6',
};

export class ThemeStore {
  /** 字体主题 */
  @observable theme: Theme = Theme.Normal;

  /** 在 app.tsx componentDidMount 给 body 挂了对应版本的 class，这里 replace 就好 */
  @action setTheme = (theme: Theme) => {
    document.body.className = document.body.className.replace(this.theme, theme);
    this.theme = theme;
  };

  @computed get isThemeOld() {
    return this.theme === Theme.OldPerson;
  }

  /** 颜色主题 */
  @observable themeStyle: CSSProperties = {};

  @action setThemeStyle = (themeStyle: CSSProperties) => {
    this.themeStyle = themeStyle;
  };

  @computed get aiThemeStyle() {
    const bgColor = this.themeStyle.background || 'rgba(32,137,246,1)';
    const calcColor = isColorLight(bgColor as string, 220) ? 'rgba(0, 0, 0, 0.85)' : '#8ABAFF';
    return this.root.permissionStore.isAIReception ? { ...this.themeStyle, color: calcColor } : '';
  }

  root: RootStore;
  constructor(root: RootStore) {
    this.root = root;
  }
}

export default ThemeStore;
