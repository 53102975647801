import { createRouter } from '@tarojs/taro'
import component from "./app"
import { createReactApp, window } from '@tarojs/runtime'
import { defineCustomElements, applyPolyfills } from '@tarojs/components/loader'

import * as React from 'react'
import ReactDOM from 'react-dom'

import '@tarojs/components/dist/taro-components/taro-components.css'

applyPolyfills().then(function () {
  defineCustomElements(window)
})

var config = {"router":{"mode":"browser"},"pages":["pages/online-service/index","pages/online-service/pure-text/index","pages/404/index","pages/guide-service/index","pages/hot-question-answer/index","pages/wx-config/index","pages/video-preview/index","pages/docs-preview/index","pages/config-preview/index","pages/config-preview-web/index","pages/feedback/index","pages/fullscreen-signature-pad/index","pages/identity-auth/index","pages/identity-form-before-auth/index","pages/identity-certificate/index","pages/certificate-ocr/index","pages/identity-upload/index","pages/config-page/index","pages/shunt-page/index"],"window":{"backgroundTextStyle":"light","navigationBarBackgroundColor":"#fff","navigationBarTitleText":"在线客户","navigationBarTextStyle":"black"}}
window.__taroAppConfig = config

if (config.tabBar) {
  var tabbarList = config.tabBar.list
  for (var i = 0; i < tabbarList.length; i++) {
    var t = tabbarList[i]
    if (t.iconPath) {
      t.iconPath = tabbarIconPath[i]
    }
    if (t.selectedIconPath) {
      t.selectedIconPath = tabbarSelectedIconPath[i]
    }
  }
}
config.routes = [
  
  Object.assign({
      path: 'pages/online-service/index',
      load: function() {
          return import("./pages/online-service/index")
      }
  }, require("./pages/online-service/index.config.ts").default || {}),

  Object.assign({
      path: 'pages/online-service/pure-text/index',
      load: function() {
          return import("./pages/online-service/pure-text/index")
      }
  }, require("./pages/online-service/pure-text/index.config.ts").default || {}),

  Object.assign({
      path: 'pages/404/index',
      load: function() {
          return import("./pages/404/index")
      }
  }, require("./pages/404/index.config.ts").default || {}),

  Object.assign({
      path: 'pages/guide-service/index',
      load: function() {
          return import("./pages/guide-service/index")
      }
  }, require("./pages/guide-service/index.config.ts").default || {}),

  Object.assign({
      path: 'pages/hot-question-answer/index',
      load: function() {
          return import("./pages/hot-question-answer/index")
      }
  }, require("./pages/hot-question-answer/index.config.ts").default || {}),

  Object.assign({
      path: 'pages/wx-config/index',
      load: function() {
          return import("./pages/wx-config/index")
      }
  }, require("./pages/wx-config/index.config.ts").default || {}),

  Object.assign({
      path: 'pages/video-preview/index',
      load: function() {
          return import("./pages/video-preview/index")
      }
  }, require("./pages/video-preview/index.config.ts").default || {}),

  Object.assign({
      path: 'pages/docs-preview/index',
      load: function() {
          return import("./pages/docs-preview/index")
      }
  }, require("./pages/docs-preview/index.config.ts").default || {}),

  Object.assign({
      path: 'pages/config-preview/index',
      load: function() {
          return import("./pages/config-preview/index")
      }
  }, require("./pages/config-preview/index.config.ts").default || {}),

  Object.assign({
      path: 'pages/config-preview-web/index',
      load: function() {
          return import("./pages/config-preview-web/index")
      }
  }, require("./pages/config-preview-web/index.config.ts").default || {}),

  Object.assign({
      path: 'pages/feedback/index',
      load: function() {
          return import("./pages/feedback/index")
      }
  }, require("./pages/feedback/index.config.ts").default || {}),

  Object.assign({
      path: 'pages/fullscreen-signature-pad/index',
      load: function() {
          return import("./pages/fullscreen-signature-pad/index")
      }
  }, require("./pages/fullscreen-signature-pad/index.config.ts").default || {}),

  Object.assign({
      path: 'pages/identity-auth/index',
      load: function() {
          return import("./pages/identity-auth/index")
      }
  }, require("./pages/identity-auth/index.config.ts").default || {}),

  Object.assign({
      path: 'pages/identity-form-before-auth/index',
      load: function() {
          return import("./pages/identity-form-before-auth/index")
      }
  }, require("./pages/identity-form-before-auth/index.config.ts").default || {}),

  Object.assign({
      path: 'pages/identity-certificate/index',
      load: function() {
          return import("./pages/identity-certificate/index")
      }
  }, require("./pages/identity-certificate/index.config.ts").default || {}),

  Object.assign({
      path: 'pages/certificate-ocr/index',
      load: function() {
          return import("./pages/certificate-ocr/index")
      }
  }, require("./pages/certificate-ocr/index.config.ts").default || {}),

  Object.assign({
      path: 'pages/identity-upload/index',
      load: function() {
          return import("./pages/identity-upload/index")
      }
  }, require("./pages/identity-upload/index.config.ts").default || {}),

  Object.assign({
      path: 'pages/config-page/index',
      load: function() {
          return import("./pages/config-page/index")
      }
  }, require("./pages/config-page/index.config.ts").default || {}),

  Object.assign({
      path: 'pages/shunt-page/index',
      load: function() {
          return import("./pages/shunt-page/index")
      }
  }, require("./pages/shunt-page/index.config.ts").default || {}),

]

var inst = createReactApp(component, React, ReactDOM, config)
createRouter(inst, config, React)
