const storage = window.localStorage;

type OptionsType = {
  /**
   * 过期时间，单位“天”
   */
  expire?: number;
  /**
   * 版本
   */
  version?: string;
};

type WrappedValueType<T> = {
  value: T;
  expire: number;
  version: string;
};

export enum StorageKeys {
  /** sourceId与visitor */
  VisitorStorage = 'VisitorStorage',
  /** 记录是否提示过语音播报 */
  TextTrVoiceHasShown = 'TextTrVoiceHasShown',
}

export class LocalStorage {
  /**
   * 将数据设置到localStroage里面
   *
   * 这个函数会将基本数据包一层，提供了过期时间及版本信息
   *
   * @param key
   * @param value
   * @param options
   */
  static set(key: string, value: any, options?: OptionsType): void {
    options = options || {};

    const expire: number = options.expire || 7;
    const wrappedValue: WrappedValueType<any> = {
      value,
      expire: Date.now() + expire * 24 * 3600 * 1000,
      version: options.version || '0.0.1',
    };
    const wrappedValueString: string = JSON.stringify(wrappedValue);

    try {
      storage.setItem(key, wrappedValueString);
    } catch (e) {
      if (e.code === 22) {
        storage.clear();
      }
    }
  }

  /**
   * 获取localStorage里面的数据
   * @param key
   * @param options
   */
  static get<T>(key: string, options?: OptionsType): T | undefined {
    options = options || {};
    const version = options.version || '0.0.1';

    try {
      const wrappedValue: WrappedValueType<T> = JSON.parse(storage.getItem(key) as string);
      if (wrappedValue.expire > Date.now() && version === wrappedValue.version) {
        return wrappedValue.value;
      }
    } catch (e) {
      // 不做处理
    }
  }

  /**
   * 根据提供的key值删除对应的数据
   * @param key
   */
  static remove(key: string): void {
    try {
      return storage.removeItem(key);
    } catch (e) {
      // 不做处理
    }
  }

  /**
   * 清空数据
   */
  static clear(): void {
    try {
      return storage.clear();
    } catch (e) {
      // 不做处理
    }
  }
}
export default LocalStorage;

export class SessionStorage {
  static setData = (key: string, value: any) => {
    window?.sessionStorage?.setItem?.(key, JSON.stringify(value));
  };

  static getData = <T>(key: string) => {
    try {
      const res = JSON.parse(window?.sessionStorage?.getItem?.(key) ?? '""');
      return res as T;
    } catch (error) {
      console.log('取值失败');
      return (null as unknown) as T;
    }
  };
}

export enum SessionStorageKey {
  /** 智慧导服大字版主题标识 */
  Theme = 'theme_v1',
}
