export enum IdentityAuthResult {
  notStart = 1,
  authSuccess,
  authFailed,
}

export type IdentityInfo = {
  result: IdentityAuthResult;
  url: string;
  authPageVisible: boolean;
  isFinished: boolean;
};

export enum IdentityAuthEvent {
  modifyAuth = 'modify_auth',
  pushAuth = 'push_confirm',
  AIPushAuth = 'ai_push_confirm',
  cancelAuth = 'cancel_auth',
  authSuccess = 'auth_success',
  authFailed = 'auth_failed',
}

export const IdentityAuthEventText = {
  [IdentityAuthEvent.modifyAuth]: '修改了',
  [IdentityAuthEvent.pushAuth]: '推送了',
  [IdentityAuthEvent.AIPushAuth]: '推送了',
  [IdentityAuthEvent.cancelAuth]: '撤销了',
  [IdentityAuthEvent.authSuccess]: '验证成功',
  [IdentityAuthEvent.authFailed]: '验证失败',
};

export enum IdentityPushType {
  Artificial = 1,
  AI = 2,
}

export interface IdentityAuthDetailData {
  /**
   * 申请人
   */
  realName: string;
  /**
   * 身份证号
   */
  idNO: string;
  /**
   * 表单名称
   */
  formName: string;
  /**
   * 流水号
   */
  serialNO: string;
  /**
   * 联系方式
   */
  telephone: string;
  /**
   * 认证id
   */
  authId: number;
  /**
   * 身份认证类型，人工，还是智能客服
   */
  feedbackType: IdentityPushType;
}

export interface IdentityAuthNoticeData {
  /**
   * 更新时间
   */
  noticeTime: number;
  /**
   * 触发人
   */
  noticeBy: string;
  /**
   * 表单名称
   */
  formName: string;
  /**
   * 认证编号
   */
  serialNO: string;
  /**
   * 事件类型
   */
  event: IdentityAuthEvent;
}

export interface BeforeAuthFormData {
  realName: string;
  idNO: string;
  telephone: string;
  [key: string]: string;
}

export interface IGetOCRResult {
  data: string;
  token: string;
}

/** OCR 识别结果 */
export interface OCRIdentityResultType {
  /** 身份证识别结果 */
  idCardDTO: {
    /** 姓名*/
    name: string;
    /** 身份证号*/
    idNumber: string;
    /** 地址*/
    address: string;
    /** 生日*/
    birthDate: string;
    /** 性别*/
    gender: string;
    /** 民族*/
    nationality: string;
    /** 签发机关*/
    issue: string;
    /** 开始日期*/
    startDate: string;
    /**截止日期*/
    endDate: string;
  };
  /** 营业执照识别结果 */
  licenseDTO: {
    /** 地址 */
    address: string;
    /** 经营范围 */
    business: string;
    /** 注册资本 */
    capital: string;
    /** 公司注册日期 */
    establishDate: string;
    /** 公司法人 */
    legalPerson: string;
    /** 公司名称 */
    name: string;
    /** 统一社会信用代码 */
    registerNumber: string;
    /** 公司类型 */
    type: string;
    /** 公司营业期限终止日期	 */
    validPeriod: string;
  };
}

export interface ISubmitOCRResult {
  connectionId: string;
  visitorId: string;
  fileData: string;
  instanceId?: string;
  token: string;
  [propName: string]: string | undefined;
}

export enum OCROrigin {
  Feedback = '0',
  Identity = '1',
  Storage = '2',
}

export enum IdentityOCRType {
  ID_CARD = 'ID_CARD',
  BUSINESS_LICENSE = 'BUSINESS_LICENSE',
}

export const IdentityOCRTypeMap: Record<IdentityOCRType, string> = {
  [IdentityOCRType.ID_CARD]: '身份证',
  [IdentityOCRType.BUSINESS_LICENSE]: '营业执照',
};
