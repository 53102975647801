import React, { FC, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import Taro from '@tarojs/taro';
import styled from 'styled-components';
import { reportError } from '@/utils';

const Wrapper = styled.button`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
`;

export type ISentryBtnProps = {};

const SentryBtn: FC<ISentryBtnProps> = ({}) => {
  const sendError = () => {
    reportError(new Error('测试sentry错误'), {
      info: 'ok',
      foo: { baz: 'hello', bar: { label: '测试嵌套', value: 'test' } },
    });
  };

  return <Wrapper onClick={sendError}>sentry报错测试按钮2</Wrapper>;
};

export default SentryBtn;
