/** 全局事件 */
import { IFuncLike } from '@/declare';
import EventEmitter from 'eventemitter3';
import { useEffect } from 'react';

const GlobalEvent = new EventEmitter();

/** 简单封装 useEffect + globalEvent.on
 * name @type {string} -- 事件名称
 * callback @type {IFuncLike} -- 订阅函数，务必把 callback 用到的外围状态存入 deps 中避免获取到旧值
 * deps @type {any[]} -- effect 依赖
 */
export const useSubscribe = (name: string, callback: IFuncLike, deps: any[] = []) => {
  useEffect(() => {
    GlobalEvent.on(name, callback);
    return () => {
      GlobalEvent.off(name, callback);
    };
  }, deps);
};

export enum events {
  /** sdk 重连 */
  SDK_SOCKET_RECONNECTED = 'SDK_SOCKET_RECONNECTED',
  /** 触底 */
  GOTO_BOTTOM = 'GOTO_BOTTOM',
  /** 已经加载了 */
  ON_LOAD = 'ON_LOAD',
  RECEIVE_MESSAGE = 'RECEIVE_MESSAGE',
  /** 更新回单内容 */
  UPDATE_FEEDBACK = 'UPDATE_FEEDBACK',
  /** 结束会话和客服接入会话时，应禁用继续等待按钮 */
  DISABLE_WAITING_BTN = 'DISABLE_WAITING_BTN',
  /** 回单关联的寄件信息发生变化 */
  RECEIPT_EXPRESS_CHANGE = 'RECEIPT_EXPRESS_CHANGE',
  /** 认证完成返回页面 */
  AUTH_FINISH = 'AUTH_FINISH',
  /** 消息引用 */
  MESSAGE_QUOTE = 'MESSAGE_QUOTE',
}

export default GlobalEvent;
