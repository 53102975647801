import { useCallback, useEffect, useRef } from 'react';
import Taro, { request } from '@tarojs/taro';
import { clone, cloneDeep, forEach, isObject } from 'lodash';
import axios from 'axios';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { getLocationShotUrl } from '@/service/index';
import UA from '@/utils/ua';
import { IFuncLike, ILinkFuncProps, OssImageInfo } from './../declare/index';
import { reportError, reportLog } from './log';

export const getStartConversationDate = (stamp: number) => {
  const targetDay = dayjs(stamp);
  const diffDay = dayjs().diff(targetDay, 'day');

  if (diffDay < 1) {
    return targetDay.format('HH:mm');
  }
  return targetDay.format('YYYY-MM-DD HH:mm');
};

// oss视频截取封面
export const getVideoFrameImg = (path: string, isResize?: boolean) => {
  let frameCUT = '?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast';
  if (isResize) {
    frameCUT = '?x-oss-process=video/snapshot,t_1000,f_jpg,w_300,h_240,m_fast';
  }
  return (path + frameCUT).replace('http://', 'https://');
};

export async function getShotUrl(longitude: string, latitude: string) {
  const shotUrl = await getLocationShotUrl(longitude, latitude);
  return shotUrl;
}

// url解析
export function urlParam() {
  // 浏览器 url： protocol :// hostname[:port] / path / [:parameters][?query]#fragment
  // 浙里办测试环境需要去掉 ?debug=true
  const href = process.env.IS_ZLB
    ? window.location.href.replace(/\?debug=true/g, '')
    : window.location.href;

  // 匹配以 ? 开头以 # 结尾的数据作为参数，taro
  const search = href.match(/\?.+#?/g)?.[0];

  let params: any = {};

  // 打包的环境是浙里办那么就需要 sourceId
  if (process.env.IS_ZLB) {
    params.sourceId = process.env.ZLB_SOURCE_ID;
  }

  try {
    params = { ...params, ...queryString.parse(search) };
  } catch (e) {
    console.log('url query parse error', e);
  }

  return params;
}

/**
 * 获取触摸点坐标
 * @param e 触摸相关事件 or 鼠标点击相关事件
 */
export const pointerEventToXY = (e) => {
  const position = { x: 0, y: 0 };
  const type = e.type;
  if (['touchstart', 'touchmove', 'touchend', 'touchcancel'].indexOf(type) > -1) {
    const touch = e.touches[0] || e.changedTouches[0];
    position.x = touch.pageX;
    position.y = touch.pageY;
  } else if (
    [
      'mousedown',
      'mouseup',
      'mousemove',
      'mouseover',
      'mouseout',
      'mouseenter',
      'mouseleave',
    ].indexOf(type) > -1
  ) {
    position.x = e.pageX;
    position.y = e.pageY;
  }
  return position;
};

export const checkIsWxMiniProgram = () => {
  return new Promise((resolve, reject) => {
    if (!UA.isWeixin()) return reject();
    console.log('🤖 == returnnewPromise == UA.isWeixin():', UA.isWeixin());
    try {
      window?.wx?.miniProgram?.getEnv((res) => {
        reportLog('在线客服小程序环境 ', {
          getEnv: res?.miniprogram,
        });
        if (res.miniprogram) {
          // @ts-ignore
          resolve();
        } else {
          reject();
        }
      });
    } catch (e) {
      reportLog('在线客服小程序环境检测失败', {
        err: e,
        环境: window?.wx?.miniProgram,
      });
      return reject();
    }
  });
};

/**
 * 不同小程序跳转地址使用约定的页面，不再进行配置
 * @param data
 */
export const navigateToMiniProgramImProxyPage = (data: any) => {
  const stringifyData = JSON.stringify(data);
  window.wx.miniProgram.navigateTo({
    url: `/pages/package-online-service/im-actions/index?data=${encodeURIComponent(stringifyData)}`,
  });
};

export const setFavicon = (url) => {
  const icon = document.querySelectorAll('link[rel~=shortcut]')[0];
  icon.setAttribute('href', url);
};

/**
 * params: (time: Moment)
 * 返回消息统一时间戳
 * 1. 当天：HH:mm
 * 2. 昨天：昨天 HH:mm
 * 3. 本周：星期一 HH:mm
 * 4. 今年：X月X日 HH:mm
 * 5. 其他：X年X月X日 HH:mm
 */
export const formatTimeStamp = (timestamp) => {
  const time = dayjs(timestamp);
  if (dayjs().startOf('day').isBefore(time)) {
    return time.format('HH:mm');
  } else if (dayjs().startOf('day').subtract(1, 'day').isBefore(time)) {
    return '昨天 ' + time.format('HH:mm');
  } else if (dayjs().startOf('week').isBefore(time)) {
    return time.format('dddd HH:mm');
  } else if (dayjs().startOf('year').isBefore(time)) {
    return time.format('MM月DD日 HH:mm');
  } else {
    return time.format('YYYY年MM月DD日 HH:mm');
  }
};

export const getParamsString = (params: Object, filterKey?: string[]) =>
  Object.entries(params).reduce((prev, cur) => {
    if (filterKey?.includes(cur[0])) return prev;
    if (!prev) return `?${cur.join('=')}`;
    return `${prev}&${cur.join('=')}`;
  }, '');

/** 获取oss图片信息 */
export const getOssImageInfo = (url: string) =>
  request<OssImageInfo>({
    method: 'GET',
    url: `${url}?x-oss-process=image/info`,
  });

/** 根据图片 url 获取图片大小 */
export const getRemoteFileSize = async (url: string) => {
  const response = await axios.head(url);
  const bytes = response.headers['content-length'] || 0;
  return parseFloat((+bytes / (1024 * 1024)).toFixed(3));
};

export const taroAlert = (title: string) => {
  Taro.showToast({
    title,
    icon: 'none',
  });
};

/** 扁平化对象 */
export function flatObj(obj: Object, key = '', res: Record<string, any> = {}, isArray = false) {
  if (!isObject(obj)) {
    return obj;
  }

  for (let [k, v] of Object.entries(obj)) {
    if (Array.isArray(v)) {
      let tmp = isArray ? key + '[' + k + ']' : key + k;
      flatObj(v, tmp, res, true);
    } else if (typeof v === 'object') {
      let tmp = isArray ? key + '[' + k + '].' : key + k + '.';
      flatObj(v, tmp, res);
    } else {
      let tmp = isArray ? key + '[' + k + ']' : key + k;
      res[tmp] = v;
    }
  }
  return res;
}

type IEnv = keyof { local; dev; test; production };
/** 获取真实的 env 情况 */
export const REAL_ENV = (() => {
  const configEnv = (process.env.NODE_ENV as IEnv) || 'production';
  return window.location.href.includes(':10086') ? 'local' : configEnv;
})();

interface IDebounce {
  fn: Function;
  timer?: ReturnType<typeof setTimeout>;
}

export const useDebounce = <T extends (...ags: any[]) => any>(
  fn: T,
  delay: number,
  deps: any[] = [],
) => {
  const { current } = useRef<IDebounce>({ fn, timer: undefined });
  useEffect(
    () => {
      current.fn = fn;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fn],
  );

  return useCallback(
    function (...args) {
      if (current.timer) {
        clearTimeout(current.timer);
      }
      current.timer = setTimeout(() => {
        current.fn.call(this, ...args);
      }, delay);
    } as T,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [current.fn, current.timer, delay, ...deps],
  );
};

/** 函数式调用 link */
export const link = ({ ...linkNodeProps }: ILinkFuncProps) => {
  const a = document.createElement('a');
  a.setAttribute('style', 'display:none');
  forEach(linkNodeProps, (val, key) => {
    a[key] = val;
  });
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

/** 链接白名单 */
const OPEN_LINKS = [
  'bai.h5.esign.cn',
  'card.byrobot.cn',
  'courier-source.byai.com',
  'courier-source.xinoa.cc',
];

/** 复制或打开链接
 * 链接白名单
 */
export const copyOpen = async (url: string) => {
  if (OPEN_LINKS.some((item) => url?.includes(item))) {
    window.open(url);
    return false;
  }
  return Taro?.setClipboardData({ data: url }).then(
    () => true,
    (err) => {
      throw err;
    },
  );
};

export const pxTransform = (size: number) => {
  return Taro.pxTransform(size, 750);
};

/** json */
export const jsonParse = <T>(str: string) => {
  let res: T | null = null;
  try {
    res = JSON.parse(str);
    return res;
  } catch (error) {
    return null;
  }
};

export type IDecorateOption = {
  cloneProps?: boolean;
  bind?: object;
};

type IDecorate = {
  <F extends IFuncLike>(
    source: F,
    intercept: (result: ReturnType<F>, ...args: Parameters<F>) => any,
    options: { after: true } & IDecorateOption,
  ): F;
  <F extends IFuncLike>(
    source: F,
    intercept: (...args: Parameters<F>) => any,
    options?: { after?: false } & IDecorateOption,
  ): F;
};
/** 修饰函数 */
export const decorate: IDecorate = <F extends IFuncLike>(
  source: F,
  interceptor: IFuncLike,
  options: IDecorateOption & { after?: boolean } = {},
) => {
  const { cloneProps, after, bind } = options;

  function fn(...args: Parameters<F>) {
    const interceptProps = cloneProps ? cloneDeep(args) : args;
    if (!after) {
      interceptor(...interceptProps);
    }
    const res = source.apply(this, args);
    if (after) {
      interceptor(...[res, ...interceptProps]);
    }
    return res;
  }

  if (bind) {
    fn.bind(bind);
  }

  return fn;
};

export const isJson = (values) => {
  try {
    JSON.parse(values);
    return true;
  } catch (error) {
    return false;
  }
};

/** 约等于 */
export const apEq = (v1: number, v2: number, epsilon = 0.001) => Math.abs(v1 - v2) < epsilon;
