import Client, { SocketProvider } from '@indata/im-sdk';
import Ajax from '@/utils/ajax';
import { taroAlert, urlParam } from '@/utils/common';
import LocalStorage, { StorageKeys } from '@/utils/localstorage';
import globalEvent, { events } from '@/utils/event';
import { reportError, reportLog } from '@/utils';
import { ILoginSuccessResponse } from '@/declare';
import { VisitorInfo } from '@/declare/feedback';
import { CourierSourceType } from '@/declare/user';
import { closeConversationService, fetchSessionStatus } from '@/service';
import { getH5Token, getMiniToken } from '@/service/token';

import store from '../index';

function getBrowserInfo() {
  let sBrowser;
  const sUsrAg = navigator.userAgent;

  // The order matters here, and this may report false positives for unlisted browsers.
  if (sUsrAg.indexOf('Firefox') > -1) {
    sBrowser = 'Mozilla Firefox';
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
    sBrowser = 'Opera';
    //"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (sUsrAg.indexOf('Trident') > -1) {
    sBrowser = 'Microsoft Internet Explorer';
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (sUsrAg.indexOf('Edge') > -1) {
    sBrowser = 'Microsoft Edge';
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (sUsrAg.indexOf('Chrome') > -1) {
    sBrowser = 'Google Chrome or Chromium';
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (sUsrAg.indexOf('Safari') > -1) {
    sBrowser = 'Apple Safari';
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    sBrowser = 'unknown';
  }

  return sBrowser;
}

function getBrowserId() {
  const aKeys = ['MSIE', 'Firefox', 'Safari', 'Chrome', 'Opera'];
  const sUsrAg = navigator.userAgent;
  let nIdx = aKeys.length - 1;

  for (nIdx; nIdx > -1 && sUsrAg.indexOf(aKeys[nIdx]) === -1; nIdx--);

  return nIdx;
}

// 目前只支持姓名、联系方式和具体来源传参
const DEFAULT_KEYS = ['name', 'phone', 'source'];
/**
 * 获取链接传参参数
 * @param metadata
 */
function getCustomParams(metadata) {
  let data = {};
  try {
    data = JSON.parse(metadata);
  } catch (e) {}

  const params = Object.keys(data).reduce((result, key) => {
    if (DEFAULT_KEYS.indexOf(key) > -1) {
      result[key] = data[key];
    } else {
      // 不识别的参数全部当做自定义参数
      result.customInfoStr = result.customInfoStr || {};
      result.customInfoStr[key] = data[key];
    }

    return result;
  }, {} as any);

  if (params.customInfoStr) params.customInfoStr = JSON.stringify(params.customInfoStr);
  // console.log('params', params);
  return params;
}

// token缓存
let tokenData;
async function getToken() {
  if (tokenData) return tokenData;
  let { sourceId, metadata, code } = store.sceneStore.urlParams;
  const params = getCustomParams(metadata);
  // code是打通企业微信账户信息用的
  if (code) params.oauthUserInfoCode = code;
  if (!sourceId) {
    sourceId = urlParam()?.sourceId || '';
    store.sceneStore.setUrlParams(urlParam());
  }

  // 获取本地用户数据
  const visitorStore = (LocalStorage.get(StorageKeys.VisitorStorage) || {}) as Record<string, any>;
  const IP_INFO = window.returnCitySN || { cip: '', cname: '' };
  console.log('IP_INFO', IP_INFO);

  /** 自助收件逻辑，
   * h5 导服的收件是通过 getH5Token 使用 url中 companyId 进行 token 登录
   * 小程序收件是通过 getMiniToken 使用 url 中 visitorId 进行 token 登录
   *  */

  const { visitorId, companyId } = urlParam();

  let visitorInfo: VisitorInfo;
  // url 中有小程序传来的 visitorId 使用小程序的登录
  if (visitorId) {
    const res = await getMiniToken(visitorId);
    visitorInfo = {
      name: res.name,
      avatar: res.avatar,
      imToken: res.token,
      visitorId,
    };
  }
  // 非小程序渠道打开 h5 页面
  else {
    visitorInfo = await getH5Token({ ...params, sourceId, companyId });
  }

  // sourceId与visitorId 对应存值
  store.sceneStore.setUserId(visitorInfo.visitorId);
  store.sceneStore.setToken(visitorInfo.imToken);
  store.sceneStore.setCourierTokenType(visitorId ? CourierSourceType.Mini : CourierSourceType.H5);

  visitorStore[sourceId] = visitorInfo.visitorId;
  LocalStorage.set(StorageKeys.VisitorStorage, {
    ...visitorStore,
  });

  await judgeSessionLogic(visitorInfo.visitorId, sourceId);

  tokenData = visitorInfo;
  return visitorInfo;
}
/** 最大重连次数 60 次 * 5 秒 = 300s = 5分钟 */
const MAX_RETRY_COUNT = 60;
/** 重连间隔 5 秒 */
const TRY_INTERVAL = 5 * 1000;
const CONNECTION_OPTION = {
  url: process.env.WS_URL,
  socketProvider: SocketProvider.DOM,
  /** 单次连接超时 */
  connectTimeout: 15000,
  /** 最大重连次数 */
  maxRetryAttempts: MAX_RETRY_COUNT,
  /** 重连间隔 */
  tryInterval: TRY_INTERVAL,
  /** 加速度 */
  tryAccelerate: 1,
};

let retryCount = 0;
const initImSdk = async (reconnect = false) => {
  let tokenObj: { imToken: string; name: string; avatar: string };
  try {
    tokenObj = await getToken();
  } catch (error) {
    // 断网状态就不往下走了
    return new Promise((_, reject) => createClientAndReconnect(error, 'reconnect-fail', reject));
  }
  const { imToken, name, avatar } = tokenObj;

  return new Promise<any>((resolve, reject) => {
    const client = new Client(
      {
        endPoint: 'web',
        token: imToken,
      },
      CONNECTION_OPTION,
    );

    client
      .on('loginSuccess', (body: ILoginSuccessResponse) => {
        console.log('ImSDK auth success', body);
        console.log('ImSDK auth success', { clientRetry: client.reconnectTryCount });

        // 1. 当 loginFail 本项目通过 eventEmitter 触发重连，重新生成 imToken 和 client
        // 2. imSdk 在断网 on.('error') 时会使用原来的 imToken 在内部进行重连，client内部的 retryCount 会自动加 1
        // 两种重连的情况都应该拉取一次会话记录，避免坐席端在重连期间发送的消息丢失
        if (client.reconnectTryCount > 0 || reconnect) {
          console.log('ImSDK reconnect success', {
            body,
            retryCount,
            innerRetryCount: client.reconnectTryCount,
          });
          client.reconnectTryCount = 0;
          retryCount = 0;
          store.ws.refreshActiveList().then(() => {
            globalEvent.emit(events.GOTO_BOTTOM);
            console.log('ws 重连成功', {
              body,
              retryCount,
              msgList: JSON.stringify(store?.ws?.liveChatList),
            });
            reportLog('ws 重连成功', {
              body,
              retryCount,
              msgList: JSON.stringify(store?.ws?.liveChatList),
            });
          });
        }

        const { data } = body;
        resolve({
          client,
          connectionId: data.connectionId,
          name,
          avatar,
        });
        isReconnecting = false;
      })
      .on('loginFail', (body) => {
        createClientAndReconnect(body, 'loginFail', reject);
      })
      .on('error', (error) => {
        createClientAndReconnect(error, 'error', reject);
      })
      .on('close', (value) => {
        createClientAndReconnect(value, 'close', reject);
      });
    client.connect();
  });
};

/** 是否正在重连 */
let isReconnecting = false;
/** 处理 ws 断开重连 */
const createClientAndReconnect = (
  error: any,
  way: 'close' | 'error' | 'loginFail' | 'reconnect-fail',
  reject: (way: string) => void,
) => {
  reject(way);
  /** 正在重连的情况下只处理 'reconnect-fail' */
  if (isReconnecting && way !== 'reconnect-fail') {
    return;
  }

  isReconnecting = true;

  // 触发e close、error、loginFail 都放弃之前的 Websocket 实例重新获取 token 和 client
  retryCount++;
  const errMsg = { error, retryCount, way };

  // 网络连接断开，上报 sentry
  console.log('ws 出错或断连', errMsg);
  reportLog('ws 出错或断连', errMsg);

  // 超过最大重连次数，关闭sdk
  if (retryCount >= MAX_RETRY_COUNT) {
    taroAlert('无法连接到消息服务器，请检查您的网络并刷新页面！');
    return;
  }

  // 断网了，触发重连机制
  setTimeout(() => {
    // 清空tokenData
    tokenData = '';
    globalEvent.emit(events.SDK_SOCKET_RECONNECTED);
  }, TRY_INTERVAL);
};

/** 当渠道切换时关闭访客当前活跃的会话开始新的会话 */
async function judgeSessionLogic(visitorId: string, sourceId: string): Promise<boolean> {
  //     是否有其他会话 有的话会话id是           之前业务线的 id                                            这个 sourceId 完全没用，为了接口通过
  const {
    isServing,
    conversationId,
    conversationServiceId,
    businessNo: sourceIdBefore,
  } = await fetchSessionStatus({ visitorId, sourceId });

  console.log({ isServing, sceneKeyBefore: sourceIdBefore, sourceId });

  // 无会话、非无渠道聊天(非自助收件、受理记录)、或者会话对应业务线相同 -- 继续正常跳转聊天逻辑
  if (!isServing || (sourceIdBefore && sourceId === sourceIdBefore)) {
    return true;
  }

  // 有会话调用结束会话接口                                                    这个 sourceId 完全没用，为了接口通过
  const res = await closeConversationService({ conversationServiceId, conversationId, sourceId });

  // 成功关闭会话
  if (res !== false) return true;

  taroAlert('未能结束当前会话，无法开启新的会话！');
  return false;
}

export default initImSdk;
