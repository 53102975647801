import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store';
import { Theme } from '@/store/theme';
import './index.scss';

export type IThemeBtnProps = {};

const ThemeBtn: FC<IThemeBtnProps> = ({}) => {
  const {
    themeStore: { theme, setTheme },
  } = useStores();

  const handleClick = () => {
    setTheme(theme === Theme.Normal ? Theme.OldPerson : Theme.Normal);
  };

  return (
    <div className='btnss' onClick={handleClick}>
      <div className='version'>版本：{theme === Theme.Normal ? '普通版' : '长者版'}</div> ///
      <div className='switch'>切换</div>
    </div>
  );
};

export default observer(ThemeBtn);
