export default class Messenger {
  msgSource: any;
  targetOrigin: string;
  actions: Record<string, any>;

  constructor(msgSource, targetOrigin) {
    this.msgSource = msgSource;
    this.targetOrigin = targetOrigin;
    this.actions = {};
    this.handleMessageListener = this.handleMessageListener.bind(this);

    window.addEventListener('message', this.handleMessageListener, false);
  }

  handleMessageListener(event) {
    // if (event.origin !== this.targetOrigin) {
    //   return console.warn(`${event.origin}不对应源${this.targetOrigin}`);
    // }

    if (!event?.data || !event?.data?.type) {
      return;
    }
    const type = event.data.type;
    if (!this.actions[type]) {
      return console.warn(`${type}: missing listener`);
    }

    this.actions[type](event.data.value);
  }

  on(type, cb) {
    this.actions[type] = cb;
    return this;
  }

  emit(type, value) {
    this.msgSource.postMessage(
      {
        type,
        value,
      },
      this.targetOrigin,
    );
    return this;
  }

  destroy() {
    window.removeEventListener('message', this.handleMessageListener);
  }
}
