/**
 * 用于处理多请求并发的 api
 */
const DEFAULT_TIMEOUT = 10000;
const PLACEHOLDER = '__PLACEHOLDER';
const DEFAULT_CONCURRENT_NUM = 10;
/** 超时处理 */
function promiseTimeoutHandler<T>(promise: Promise<T>, timeout = DEFAULT_TIMEOUT): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    const timer = setTimeout(() => {
      reject('已超时');
    }, timeout);
    promise.finally(() => clearTimeout(timer)).then(resolve, reject);
  });
}

/** 创建赛道 */
function createTrack(trackNum = DEFAULT_CONCURRENT_NUM) {
  if (trackNum < 1) {
    console.error('创建赛道宽度至少为1');
  }
  const track = new Array<Promise<string>>(trackNum).fill(Promise.resolve(PLACEHOLDER));
  return track;
}

/** 默认单个选手失败回调 */
const defaultCatchCallback = () => (reason: unknown) => {
  //  console.log('失败原因：', reason);
};

/** 持续填充有空位的赛道
 * @param track 赛道主体
 * @param playerList 填充的选手列表
 * @param timeout 单个选手限制时间
 * @param catchCallback 单个选手失败时处理
 * @param promiseCreator 统一创建 promise 函数
 *
 * @return Promise 类型，所有promise都成功时该 promise 成功
 */
function fillTrack<RES, PL>(
  track: Promise<RES | string>[],
  playerList: PL[],
  options?: {
    timeout?: number;
    catchCallback?: (reason: unknown, index: number) => unknown;
    promiseCreator?: PL extends Function ? null : (params: PL, index: number) => Promise<RES>;
  },
) {
  let { timeout = DEFAULT_TIMEOUT, catchCallback = defaultCatchCallback, promiseCreator } =
    options || {};
  return new Promise<FinishData<RES>>((resolve) => {
    // 遍历 playerList 计数(已进入赛道的 player 个数)
    let count = 0;
    // 几个赛道报告结束了
    let overCount = 0;
    // 收集结果
    const results: ResultItem<RES>[] = [];
    const successList: RES[] = [];
    const failList: Reason[] = [];

    // 检查赛道空位
    track.forEach((player, index) => {
      // 一个 player 完成比赛后执行的函数
      const finallyCallback = () => {
        // 计数超过总选手个数、该赛道完成比赛
        if (count >= playerList.length) {
          overCount++;
          // 每个赛道都完成比赛，整场比赛结束，所有 promise 完成
          if (overCount === track.length) {
            resolve({ results, successList, failList });
          }
          return;
        }
        // 产生一个新的 player
        let newPlayer = promiseCreator
          ? promiseCreator(playerList[count], count)
          : playerList[count] instanceof Function
          ? ((playerList[count] as unknown) as Function)()
          : Promise.resolve(playerList[count]);
        // 处理超时
        newPlayer = promiseTimeoutHandler(newPlayer, timeout);
        // 新选手加入比赛
        track[index] = newPlayer;
        // 收集每一次成功时的结果 (记录当前count，因为count是在循环之外的，也就是说 .then 时已经变化了)
        const memoCount = count;
        // 新选手完成后同样适用 callback
        newPlayer
          .then(
            (value: RES) => {
              results[memoCount] = { value, isSuccess: true };
              successList[memoCount] = value;
            },
            (reason: any) => {
              results[memoCount] = { value: reason, isSuccess: true };
              failList[memoCount] = reason;
              catchCallback(new Reason(reason), memoCount);
            },
          )
          .finally(finallyCallback);
        count++;
      };
      // player.catch((reason)=>catchCallback(reason, index)).finally(finallyCallback);
      player.finally(finallyCallback);
    });
  });
}

class Reason {
  error: any;
  constructor(error: any) {
    this.error = error;
  }
}

type ResultItem<RES> =
  | {
      value: RES;
      isSuccess: true;
    }
  | {
      value: Reason;
      isSuccess: false;
    };

type FinishData<RES> = {
  results: ResultItem<RES>[];
  successList: RES[];
  failList: Reason[];
};

export { createTrack, fillTrack };
