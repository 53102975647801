import Taro from '@tarojs/taro';
import store from '@/store';
import { CourierSourceType } from '@/declare/user';
import { forEach } from 'lodash';
import LocalStorage, { StorageKeys } from './localstorage';
import { queryDecode64 } from './url';

const IS_PRODUCTION = process.env.NODE_ENV === 'production';

// 设置域名 -- 浙里办构建时需要判断环境请求对应接口。 非浙里办环境本地有代理，线上有 nginx 所以可以为空字符串
export const LIVE_API_URL =
  process.env.IS_ZLB && !process.env.IS_ZLB_LOCAL
    ? IS_PRODUCTION
      ? 'https://aicc.byai.com'
      : 'https://by.xinoa.cc'
    : '';

// TODO: 封装通用的包
function debugLog(method, url, resData, params) {
  if (!IS_PRODUCTION) {
    // Taro.showModal({
    //   title: '哇喔，接口出错了(开发环境独有提示)',
    //   content: method + url + '\n' + resData.resultMsg + JSON.stringify(params),
    // });
  }
}

const aa = window.location.search.slice(1);
// 非聊天页所调用的接口可以通过 companyId 来获取
const { companyId } = queryDecode64<{ id: string; companyId: string }>(aa, 'en');
console.log('companyId', companyId);

export function promiseRequest<T>(
  url: string,
  data: any = {},
  method: 'OPTIONS' | 'GET' | 'HEAD' | 'POST' | 'PUT' | 'DELETE' | 'TRACE' | 'CONNECT' = 'GET',
  header = {},
  dataType = 'json',
) {
  return new Promise<T>((resolve, reject) => {
    Taro.request({
      data,
      dataType,
      method,
      url: /^\/[^\/]/.test(url) ? LIVE_API_URL + url : url,
      header,
      success(rtn) {
        const resData = rtn.data;
        if (resData.code !== 200 && resData.code !== 400) {
          console.error('接口错误:', url, method, data, resData);
          debugLog(method, url, resData, data);
          return reject(resData);
        }
        resolve(resData.data);
      },
      fail(err) {
        console.error(err);
        return reject();
      },
    });
  });
}

const Ajax = {
  ajax<T>(
    url: string,
    data = {},
    method: 'OPTIONS' | 'GET' | 'HEAD' | 'POST' | 'PUT' | 'DELETE' | 'TRACE' | 'CONNECT' = 'GET',
    header = {},
    dataType = 'json',
  ): Promise<T> {
    const { sourceId } = store.sceneStore.urlParams;
    const visitorStore = (LocalStorage.get(StorageKeys.VisitorStorage) || {}) as Record<
      string,
      string
    >;
    // 将 url 中 undefined 的值去掉
    url = url.replace(/[\s\S]\w+=(undefined|null)/g, '');
    if (method === 'GET') {
      const temp: object = {};
      forEach(data, (val, key) => {
        if (val != null) {
          temp[key] = val;
        }
      });
      data = temp;
    }

    return new Promise((resolve, reject) => {
      Taro.request({
        data,
        dataType,
        method,
        url: /^\/[^\/]/.test(url) ? LIVE_API_URL + url : url,
        header: {
          tokentype: 'visitor',
          token: visitorStore[sourceId] || store.sceneStore.userId,
          sourceType: store?.sceneStore?.courierSourceType || CourierSourceType.H5,
          companyId,
          ...header,
        },
        success(rtn) {
          const resData = rtn.data;
          if (
            store?.sceneStore?.courierSourceType === CourierSourceType.H5 &&
            resData.code === 406
          ) {
            Taro.showToast({
              title: '当前渠道不存在',
              icon: 'none',
            });
            return reject(resData);
          }
          if (resData.code !== 200 && resData.code !== 400) {
            if (resData.message) {
              Taro.showToast({
                title: resData.message,
                duration: 3000,
                icon: 'none',
              });
            }
            console.error('接口错误:', url, method, data, resData);
            debugLog(method, url, resData, data);
            return reject(resData);
          }
          resolve(resData.data);
        },
        fail(err) {
          console.error(err);
          return reject(err);
        },
      });
    });
  },
  form<T>(url: string, data: Record<string, any>, header = {}) {
    const formHeader = {
      'content-type': 'application/x-www-form-urlencoded',
    };
    return Ajax.ajax<T>(url, data, 'POST', { ...formHeader, ...header });
  },
  get<T>(url: string, data: Record<string, any> = {}, header: Record<string, any> = {}) {
    return Ajax.ajax<T>(url, data, 'GET', header);
  },
  post<T>(url: string, data: Record<string, any> = {}, header: Record<string, any> = {}) {
    const formHeader = {
      'content-type': 'application/json;charset=UTF-8',
    };
    return Ajax.ajax<T>(url, data, 'POST', { ...formHeader, ...header });
  },
  put<T>(url: string, data: Record<string, any>, header: Record<string, any> = {}) {
    return Ajax.ajax<T>(url, data, 'PUT', {
      'content-type': 'application/json;charset=UTF-8',
      ...header,
    });
  },
  delete<T>(url: string, data: Record<string, any> = {}) {
    return Ajax.ajax<T>(url, data, 'DELETE');
  },
};

export default Ajax;
